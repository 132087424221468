<template>
	<v-banner
		v-if="status?.enabled && status?.banner"
		:color="statusColorEnum[statusEnum.indexOf(status.status)]"
		dark
		elevation="5"
		icon="mdi-wrench"
		sticky
	>
		<span class="font-weight-bold">
			{{ status.bannerText }}
		</span>

		<template #actions="{ dismiss }">
			<v-btn v-if="status.dismissable" @click="dismiss">
				{{ $t("close") }}
			</v-btn>
		</template>
	</v-banner>
</template>

<script>
export default {
	name: "StatusBanner",

	props: {
		status: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			statusColorEnum: ["success", "info", "warning", "warning", "error"],
			statusEnum: ["operational", "under_maintenance", "degraded_performance", "partial_outage", "major_outage"]
		};
	}
};
</script>
