<template>
	<v-list nav>
		<v-list-item
			v-for="(item, index) in items"
			v-show="!item.disabled"
			:key="index"
			link
			:to="item.route"
			:ripple="!item.submenu"
			:class="{ 'have-sub-menu': item.submenu }"
			@click="item.submenu ? toggleSubmenu(item.id) : $emit(`click:${item.id}`)"
		>
			<v-list-item-icon class="mr-2">
				<v-badge
					:value="index === 0 && notification"
					color="red"
					overlap
					dot
				>
					<v-icon color="white">
						{{ item.icon }}
					</v-icon>
				</v-badge>
			</v-list-item-icon>

			<v-list-item-content
				:class="{ 'mt-1 ml-n1': isSubmenuOpen(item.id), 'mt-0 ml-2': !isSubmenuOpen(item.id) }"
			>
				<v-list-item-title class="d-flex justify-space-between align-center">
					<span :class="{ 'pl-2': isSubmenuOpen(item.id), 'ml-0': !isSubmenuOpen(item.id) }">
					{{ $t(item.title, item.titleParams) }}
						</span>
			<v-icon v-if="item.submenu" right>
				{{ isSubmenuOpen(item.id) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
			</v-icon>
				</v-list-item-title>

				<v-list v-if="isSubmenuOpen(item.id)" class="mb-n4">
					<v-list-item
						v-for="(subItem, subIndex) in item.submenu"
						:key="subIndex"
						link
						:to="subItem.route"
						@click="$emit(`click:${subItem.id}`)"
					>
						<v-list-item-icon>
							<v-icon>{{ subItem.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content class="ml-n6">
							<v-list-item-title class="small-font">
								{{ $t(subItem.title) }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: "NavigationList",

	props: {
		items: {
			type: Array,
			required: true
		},

		notification: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			openSubmenu: null
		};
	},

	methods: {
		toggleSubmenu(id) {
			this.openSubmenu = this.openSubmenu === id ? null : id;
		},

		isSubmenuOpen(id) {
			return this.openSubmenu === id;
		}
	}
};
</script>
<style scoped>
.small-font {
	font-size: 15px;
}

.have-sub-menu:hover:has(.v-list-item:hover)::before {
	opacity: 0 !important;
}

.v-list-item {
	margin-bottom: 0 !important;
}
</style>
