var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "pb-3",
      attrs: { "align-center": "", "justify-center": "", column: "" },
    },
    [
      _c(
        "v-card",
        {
          staticStyle: { "background-color": "var(--v-light-primary-base)" },
          attrs: { flat: "" },
        },
        [
          _c(
            "v-card-text",
            [
              _c("v-text-field", {
                staticClass: "centered-input text-h6 font-weight-semibold",
                attrs: {
                  value: _vm.digitsPressed,
                  flat: "",
                  solo: "",
                  readonly: "",
                  "background-color": "light-primary",
                },
              }),
              _c(
                "div",
                { staticClass: "mt-n6" },
                _vm._l(_vm.buttons, function (row, rowIndex) {
                  return _c(
                    "v-layout",
                    {
                      key: rowIndex,
                      attrs: {
                        "no-gutters": "",
                        "align-center": "",
                        "justify-center": "",
                      },
                    },
                    _vm._l(row, function (button, buttonIndex) {
                      return _c(
                        "v-btn",
                        {
                          key: buttonIndex,
                          staticClass: "ma-2",
                          attrs: { color: "primary", fab: "", outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.sendDigits(button)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary--text text-h4 font-weight-bold",
                            },
                            [_vm._v(" " + _vm._s(button) + " ")]
                          ),
                        ]
                      )
                    }),
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }