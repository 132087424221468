var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status?.enabled && _vm.status?.banner
    ? _c(
        "v-banner",
        {
          staticClass: "ml-12",
          attrs: {
            dark: "",
            sticky: "",
            color: "primary",
            icon: "mdi-update",
            lines: "one",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function ({ dismiss }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mt-8 mr-0",
                        attrs: {
                          absolute: "",
                          icon: "",
                          right: "",
                          color: "white",
                        },
                        on: { click: dismiss },
                      },
                      [
                        _c("v-icon", { attrs: { size: "md" } }, [
                          _vm._v(" mdi-close "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            3151597976
          ),
        },
        [
          _c("v-banner-text", { staticClass: "font-weight-bold ml-n2" }, [
            _vm._v(" " + _vm._s(_vm.status.bannerText) + " "),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }