<template>
	<v-overlay v-if="status?.enabled && status?.overlay" absolute>
		<v-row
			class="fill-height"
			align-content="center"
			justify="center"
		>
			<v-col
				class="text-subtitle-1 text-center font-weight-bold"
				cols="12"
			>
				{{ $t("loadingText") }}
			</v-col>

			<v-col cols="6">
				<v-progress-linear
					indeterminate
					rounded
					height="12"
				/>
			</v-col>
		</v-row>
	</v-overlay>
</template>

<script>
export default {
	name: "StatusOverlay",

	props: {
		status: {
			type: Object,
			required: true
		}
	}
};
</script>
