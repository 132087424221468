import Thread from "./thread";
import axios from "axios";

export default class ThreadList {
	static baseUrl = "messaging/v1/threads";

	/**
	 * @param {string} to The recipient phone number
	 * @param {string} body The body of the message
	 * @param {object} attributes The plain object attributes with key: value pairs
	 * @returns {Thread} A new Thread instance
	 */
	async create(to, body, attributes) {
		const { data: newThread } = await axios.post(`${ThreadList.baseUrl}`, {
			to,
			body,
			attributes
		});

		return new Thread(newThread);
	}

	/** @return {Thread} An existing Thread instance*/
	async findOne(threadId) {
		const { data: thread } = await axios.get(`${ThreadList.baseUrl}/${threadId}`);

		return new Thread(thread);
	}

	/** @return {Thread[]} A collection of all Thread instances */
	async findAll() {
		const { data: threads } = await axios.get(`${ThreadList.baseUrl}`);

		return threads.map(thread => new Thread(thread));
	}

	/**
	 * Update thread data/attributes
	 * @param {*} threadId The id of the thread to update
	 * @param {object} updateOptions The update payload options
	 */
	async update(threadId, updateOptions) {
		return await axios.put(`${ThreadList.baseUrl}/${threadId}`, updateOptions);
	}

	/**
	 * @param {*} threadId The id of the thread to remove
	 */
	async remove(threadId) {
		return await axios.delete(`${ThreadList.baseUrl}/${threadId}`);
	}
}