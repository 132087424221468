import store from "@/store";
import notificationSound from "@/assets/audio/notification.mp3";
import Vue from "vue";
import axios from "axios";
import { arrayBufferToBase64 } from "./mail";

export const INITIALIZING = "initializing";
export const INITIALIZATION_FAILED = "initialization-failed";
export const CONNECTION_FAILED = "connection-failed";
export const CONNECTING = "connecting";
export const CONNECTED = "connected";
export const DISCONNECTING = "disconnecting";
export const DISCONNECTED = "disconnected";
export const RECONNECTING = "reconnecting";

const audio = new Audio(notificationSound);

export const playAudio = () => {
	if (store.state.conversation.audioNotificationsEnabled) {
		audio.play().catch(() => {});
	}
};

export const toggleState = (state) => {
	console.debug("=== Conversations Client ===>", state);
	store.commit("conversation/setConnectionState", state);
	store.commit("conversation/setIsInitializing", [INITIALIZING, CONNECTING, RECONNECTING].includes(state));
};

export const desktopNotification = () => {
	if (store.state.conversation.desktopNotificationsEnabled && document.hidden) {
		Vue.notification.show("Vous avez une nouvelle notification dans Fresk!", {
			body: "",
			silent: true
		}, {});
	}
};

export const getRecordingAsBase64 = async (recording) => {
	const response = await axios.get(`recordings?url=${recording}`, {
		responseType: "arraybuffer"
	});

	const fileInBase64 = arrayBufferToBase64(response.data);

	return fileInBase64;
};

export const getRecordingLength = async (recordingId) => {
	const response = await axios.get(`recordings/length?recordingId=${recordingId}`);

	return response.data;
};
