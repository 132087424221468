import PhoneCall from "./phone-call";

class InboundCall extends PhoneCall {
	constructor(callInstance) {
		super(callInstance, true);
		this.direction = "inbound";
	}

	static from(callInstance) {
		return new InboundCall(callInstance);
	}
}

export default InboundCall;
