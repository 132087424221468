import axios from "axios";
import { getField, updateField } from "vuex-map-fields";
import router from "../router";
import useConversation from "../composables/conversation";
import messaging from "../composables/messaging";
import useVoice from "../composables/voice";
import { fullName } from "@/common/utils";

const debug = false;

const IDLE_TIMEOUT = debug ? 600000 : 43200000; // 12 hours (10 minutes in debug)

if (debug) {
	console.warn("=================================================");
	console.warn("JWT refresh and idle timers running in debug mode");
	console.warn("Enable verbose in the chrome console to view logs");
	console.warn("=================================================");
}

const conversationsHook = useConversation();

export const roles = {
	ADMIN : "admin",
	SUPER_ADMIN : "super-admin",
	DEVOPS: "devops",
	MARKETING: "marketing"
};

let idleTimeout;

export default {
	namespaced: true,
	state: () => ({
		jwt: undefined,
		expires: undefined,
		profile: {},
		userStatus: "offline",
		email: undefined,
		logoutTrigger: undefined
	}),
	mutations: {
		updateField,
		clear(state) {
			state.jwt = undefined;
			state.expires = undefined;
			state.profile = {};
			state.userStatus = "offline";
			sessionStorage.clear();
		},
		setJwt(state, jwt) {
			state.jwt = jwt;

			if (jwt?.length) {
				const [, payloadB64] = jwt.split(".");

				state.expires = JSON.parse(atob(payloadB64))?.expires;
				if (debug) console.debug(`Set JWT token ${jwt} Expires at ${state.expires}`);
			} else {
				state.expires = undefined;
			}
		},
		setUserProfile(state, user) {
			state.profile = {
				...user,
				initials: `${user.name?.first[0] || ""}${user.name?.last[0] || ""}`,
				fullName: fullName(user.name)
			};
		},
		setUserStatus(state, status) {
			if (state.userStatus !== status) {
				axios.put("/auth/status", { status }).then(() =>{
					state.userStatus = status;
				});
			}
		},
		setEmail: (state, payload) => (state.email = payload),
		setLogoutTrigger: (state, payload) => (state.logoutTrigger = payload)
	},
	actions: {
		async endSession({}, options = {}) {
			try {
				const { fromAllDevices } = options;

				await axios.post("/auth/logout", {
					revokeAll: !!fromAllDevices
				});
			} catch (err) {
				console.error("Failed to logout user:", err);
			}
		},
		async logout({ dispatch }) {
			await dispatch("endSession");
			await dispatch("showLogin");
		},
		async removeTypedMessages({}, { userId }) {
			for (let i = 0; i < localStorage.length; i++) {
				if (localStorage.key(i).startsWith(userId + "_typed_")) {
					localStorage.removeItem(localStorage.key(i));
				}
			}
		},
		async refreshToken({ commit, getters }) {
			const { data } = await axios.post("/auth/refresh", {}, {
				timeout: 30000
			});

			// Only set new jwt when user still logged in
			if (getters.loggedIn) {
				await commit("setJwt", data?.jwt);
			}
			return data?.jwt;
		},
		async sessionExpired({ dispatch }) {
			try {
				await dispatch("clearAll");
			} catch (err) {
				console.error("Failed to clean up session:", err);
			} finally {
				if (router.currentRoute.name !== "login") {
					router.replace({
						name: "login",
						params: { "session-expired": Date.now() }
					});
				}
			}

		},
		async showLogin({ dispatch }) {
			await dispatch("clearAll");

			if (router.currentRoute.name !== "login") {
				router.go("login");
			}
		},
		async expireSession({ dispatch }) {
			await dispatch("endSession");
			await dispatch("sessionExpired");
		},
		async activity({ dispatch }) {
			if (debug) console.debug("Activity!");
			await dispatch("resetIdleTimeout");
		},
		clearIdleTimeout() {
			if (debug) console.debug("Clearing idle timeout");
			clearTimeout(idleTimeout);
			idleTimeout = undefined;
		},
		async clearTimers({ dispatch }) {
			await dispatch("clearIdleTimeout");
		},
		async resetIdleTimeout({ state, dispatch }) {
			if (debug) console.debug("Reset Idle timeout");
			await dispatch("clearIdleTimeout");

			if (!state.connected) return;

			idleTimeout = setTimeout(() => {
				dispatch("expireSession");
			}, IDLE_TIMEOUT);

			if (debug) console.debug(`Reset idle timeout to ${IDLE_TIMEOUT}`);
		},
		async clearAll({ dispatch, commit }) {
			try {
				dispatch("clearTimers");
				commit("clear");
				commit("clinic/resetState", null, { root: true });
				commit("consultation/resetState", null, { root: true });
				console.debug("clearAll:setCurrentGroup!!");
				commit("conversation/setCurrentGroup", "", { root: true });
				commit("groups/resetGroups", "", { root: true });

				// Free conversations resources upon explicit logout or session expiry
				messaging.cleanup();
				useVoice.cleanup();
				conversationsHook.cleanup();
			} catch (error) {
				console.error("Error while clearing state", error);
			}
		}
	},
	getters: {
		connected: (state) => state.jwt !== undefined && Date.now() < state.expires,
		profile: (state) => state.profile,
		userStatus: (state) => state.userStatus,
		isAdmin: (state, getters) => state.profile.role === roles.ADMIN || getters.isSuperAdmin,
		isSuperAdmin: (state, getters) => state.profile.role === roles.SUPER_ADMIN || getters.isDevops,
		isDevops: (state) => state.profile.role === roles.DEVOPS,
		isMarketing: (state, getters) => state.profile.role === roles.MARKETING || getters.isDevops,
		email: (state) => state.email,
		logoutTrigger: (state) => state.logoutTrigger,
		loggedIn: (state) => state.jwt && Object.keys(state?.profile || {}).length,
		getField
	}
};
