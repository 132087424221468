var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        width: "600",
        title: _vm.$t("contact-us"),
        type: "question",
        "is-cta-disable": _vm.isCtaDisable,
        "cancel-text": _vm.$t("cancel"),
        "confirm-text": _vm.$t("send"),
      },
      on: { cancel: _vm.closeDialog, confirm: _vm.sendMessage },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c("support-form", {
        attrs: { form: _vm.form, "support-dialog": _vm.supportDialog },
        on: {
          updateValidation: _vm.updateValidation,
          onDialogClose: function ($event) {
            _vm.supportDialog = false
          },
        },
        model: {
          value: _vm.valid,
          callback: function ($$v) {
            _vm.valid = $$v
          },
          expression: "valid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }