export default class Message {
	/** @private */
	_threadId;

	/** @type {string} */
	id;

	/** @type {string} */
	author;

	/** @type {string} */
	from;

	/** @type {string} */
	to;

	/** @type {string} */
	body;

	/** @type {string} */
	messageSid;

	/**
	 * @type enum{string}
	 */
	status;

	/** @type {string} */
	direction;

	/** @type {Date} */
	createdAt;

	/** @type {Date} */
	updatedAt;

	/** @type {object} */
	attributes = {};

	/** @type {Date} Message creation date */
	dateCreated;

	constructor(messageData) {
		Object.assign(this, { ...messageData, dateCreated: new Date(messageData.createdAt) });
	}

	get sid() {
		return this.id;
	}

	get state() {
		return this.status;
	}
}