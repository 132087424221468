import axios from "axios";
import { getField, updateField } from "vuex-map-fields";

export default {
	namespaced: true,
	getters: {
		getField,
		isOpenImportContactsDialog(state) {
			return state.isOpenImportContactsDialog;
		},

		isSubmitting(state) {
			return state.isSubmitting;
		}
	},

	mutations: {
		updateField,
		setIsOpenImportContactsDialog(state, value) {
			state.isOpenImportContactsDialog = value;
		},

		setIsSubmitting(state, value) {
			state.isSubmitting = value;
		}
	},

	state: () => ({
		isOpenImportContactsDialog: false,
		isSubmitting: false,
		isDisplayContactForm: false,
		openContactDrawer: false,
		seeMoreAppointments: false,
		seeMoreNotes: false,
		seeMoreSurveys: false,
		toggleContactDrawer: true,
		selectedSurvey: undefined,
		options: {
			sortBy: ["name.last"],
			sortDesc: [false]
		}
	})
};
