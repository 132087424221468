var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        title: _vm.$t("transfer-call"),
        "sub-title": _vm.subtitleText,
        width: "600",
        type: "question",
        "cancel-text": _vm.$t("cancel"),
        "confirm-text": _vm.$t("transfer-call"),
        "confirm-loading": _vm.confirmLoading,
        "confirm-disabled": _vm.confirmDisabled,
        "extra-disabled": _vm.extraDisabled,
        "extra-text": _vm.extraButtonText,
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
        confirm: function ($event) {
          return _vm.transferCall(_vm.group)
        },
        extra: _vm.onExtraButton,
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      !_vm.showSipList && _vm.enableExtTransfer
        ? _c(
            "div",
            [
              _c(
                "v-list",
                { staticClass: "py-0 user-container mb-8 px-4" },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.extensions, function (extensionUser) {
                      return _c(
                        "v-col",
                        { key: extensionUser?.id, attrs: { cols: "6" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "rounded",
                              attrs: { elevation: "2" },
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  class: {
                                    selected:
                                      _vm.userExtension === extensionUser,
                                  },
                                  attrs: { value: extensionUser },
                                  on: {
                                    click: function ($event) {
                                      _vm.userExtension = extensionUser
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    { attrs: { color: "initials" } },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-phone-classic")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(`${extensionUser.name}`) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.showSipList && !_vm.enableExtTransfer
        ? _c(
            "div",
            [
              _c(
                "v-list",
                {
                  staticClass: "py-0 user-container mb-8",
                  attrs: { rounded: "" },
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "primary" },
                      model: {
                        value: _vm.group,
                        callback: function ($$v) {
                          _vm.group = $$v
                        },
                        expression: "group",
                      },
                    },
                    _vm._l(_vm.groups, function (userGroup) {
                      return _c(
                        "v-list-item",
                        { key: userGroup.id, attrs: { value: userGroup } },
                        [
                          _c(
                            "v-list-item-avatar",
                            { attrs: { color: "initials" } },
                            [
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v(" " + _vm._s(userGroup.label[0]) + " "),
                              ]),
                            ]
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${
                                        userGroup.label
                                      } ${_vm.groupPhoneNumber(
                                        userGroup.phone
                                      )}`
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSipList && !_vm.enableExtTransfer
        ? _c(
            "div",
            [
              _c(
                "v-list",
                { staticClass: "py-0 user-container mb-8 px-4" },
                [
                  _vm.showSipList
                    ? _c(
                        "v-row",
                        _vm._l(_vm.groupUsers, function (userGroupSip) {
                          return _c(
                            "v-col",
                            {
                              key: userGroupSip?.displayName,
                              attrs: { cols: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "rounded",
                                  attrs: { elevation: "2" },
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      class: {
                                        selected: _vm.userSip === userGroupSip,
                                      },
                                      attrs: { value: userGroupSip },
                                      on: {
                                        click: function ($event) {
                                          _vm.userSip = userGroupSip
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        { attrs: { color: "initials" } },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "white--text" },
                                            [_vm._v("mdi-phone-classic")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "v-list-item-title",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    `${userGroupSip.displayName}`
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }