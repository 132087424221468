import { getField, updateField } from "vuex-map-fields";
import { MAX_ACTIVE_ALERTS } from "../common/const";

export default {
	getters: {
		getField
	},
	mutations: {
		add(state, alert) {
			state.list.unshift({
				...alert,
				show: true
			});

			if (state.list.length > MAX_ACTIVE_ALERTS) {
				state.list.length = MAX_ACTIVE_ALERTS;
			}

			if (alert.timeout) {
				const [currentAlert] = state.list; // Get a reference to the last added alert

				setTimeout(() => {
					currentAlert.show = false;
				}, 3000);
			}
		},
		clear(state) {
			state.list = [];
		},
		updateField
	},
	namespaced: true,
	state: () => ({
		list: []
	})
};
