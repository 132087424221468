import { getField, updateField } from "vuex-map-fields";

const getDefaultState = () => ({
	appointmentForm: {
		dentist: undefined,
		category: undefined,
		service: undefined,
		date: undefined,
		duration: undefined,
		selectedAvailability: undefined,
		firstName: undefined,
		lastName: undefined,
		birthday: undefined,
		phone: undefined,
		email: undefined,
		gender: "male",
		kit: undefined,
		extraDocument: ["covid"],
		requests: undefined,
		appointmentNotes: undefined,
		computedNames: {
			dentistTitle: undefined,
			dentistName: undefined,
			categoryName: undefined
		}
	},
	appointmentId: undefined,
	attachKitDialog: false
});

export default {
	getters: {
		getField
	},
	mutations: {
		updateField,
		resetState(state) {
			Object.assign(state, getDefaultState());
		}
	},
	namespaced: true,
	state: () => ({
		appointmentForm: {
			dentist: undefined,
			category: undefined,
			service: undefined,
			date: undefined,
			duration: undefined,
			selectedAvailability: undefined,
			firstName: undefined,
			lastName: undefined,
			birthday: undefined,
			phone: undefined,
			email: undefined,
			gender: "male",
			kit: undefined,
			extraDocument: ["covid"],
			requests: undefined,
			appointmentNotes: undefined,
			computedNames: {
				dentistTitle: undefined,
				dentistName: undefined,
				categoryName: undefined
			}
		},
		appointmentId: undefined,
		attachKitDialog: false,
		isActiveAgenda: false
	})
};
