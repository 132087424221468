export default {
	install(Vue) {
		Vue.prototype.$alert = function (type, message, timeout = true, icon = undefined) {
			this.$store.commit("alerts/add", { type, message, timeout, icon });
		};

		Vue.prototype.$error = function (message, timeout = true) {
			this.$store.commit("alerts/add", { type: "error", message, timeout });
		};

		Vue.prototype.$success = function (message, timeout = true) {
			this.$store.commit("alerts/add", { type: "success", message, timeout });
		};

		Vue.prototype.$warning = function (message, timeout = true) {
			this.$store.commit("alerts/add", { type: "warning", message, timeout });
		};
	}
};
