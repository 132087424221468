<template>
	<v-container>
		<v-row>
			<v-col
				cols="10"
				offset="1"
				offset-sm="4"
				sm="4"
			>
				<slot />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {};
</script>
