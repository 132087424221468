import i18n from "../i18n";

export default {
	install(Vue) {
		Vue.prototype.LANGUAGES = ["fr-FR", "fr-CA", "en-US"];

		// Get translation from object with locales
		Vue.prototype.$ta = function (o) {
			if (o) {
				return o[i18n.locale] || o[Object.keys(o)[0]] || "";
			}
		};

		// Create translation object with value
		Vue.prototype.$tf = function (translation) {
			let o = {
				...translation
			} || {};

			for (let language of Vue.prototype.LANGUAGES) {
				o[language] = o[language] || "";
			}

			return o;
		};

		Vue.prototype.$translate = function (tr, locale = null) {
			if (tr && typeof tr === "object") {
				const first = tr[Object.keys(tr)[0]];

				if (locale) {
					return tr[locale] || first;
				} else {
					return first;
				}
			}

			console.error(`Invalid translation object ${tr}`);
			return "--";
		};
	}
};
