var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticClass: "mx-5", attrs: { column: "" } },
    [
      _c("div", { staticClass: "mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("support-instruction")) + " "),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  hint: _vm.$t("problem-info"),
                  label: _vm.$t("problem-description"),
                  rules: [
                    _vm.rules.required(_vm.form.description, "description"),
                  ],
                  "background-color": "light-primary",
                },
                model: {
                  value: _vm.inputForm.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputForm, "description", $$v)
                  },
                  expression: "inputForm.description",
                },
              }),
              _c(
                "v-layout",
                { attrs: { "align-start": "" } },
                [
                  _c("v-file-input", {
                    attrs: {
                      outlined: "",
                      "prepend-icon": "",
                      "append-icon": "mdi-paperclip",
                      dense: "",
                      label: _vm.$t("screenshot"),
                      "background-color": "light-primary",
                      accept: "image/png, image/jpeg, image/svg+xml",
                      clearable: "",
                    },
                    model: {
                      value: _vm.imgFileInput,
                      callback: function ($$v) {
                        _vm.imgFileInput = $$v
                      },
                      expression: "imgFileInput",
                    },
                  }),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-icon",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mt-2 ml-2",
                                      attrs: { color: "primary" },
                                    },
                                    "v-icon",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_vm._v(" mdi-information-outline ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("screenshot-tooltip")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }