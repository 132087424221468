var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        width: "650px",
        title: _vm.dialogTitle,
        "cancel-text": _vm.$t("dont-ask-again"),
        "confirm-text": _vm.$t("connect-page"),
        "cancel-loading": _vm.isDisconnecting,
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("v-card-text", { staticClass: "px-4 pt-0 text-subtitle-1 pb-16" }, [
        _c("div", { staticClass: "pb-2" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("email-colon")))]),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.email)),
          ]),
        ]),
        _c("div", [_vm._v(" " + _vm._s(_vm.dialogContent) + " ")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }