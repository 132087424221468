<template>
	<v-dialog
		v-model="showDraggableCallDialog"
		persistent
		hide-overlay
		max-width="350"
		:retain-focus="false"
	>
		<call-details v-if="showDraggableCallDialog" />
	</v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CallDetails from "./CallDetails.vue";

export default {
	name: "CallDraggable",

	components: {
		CallDetails
	},

	data() {
		return {
			container: {}
		};
	},

	computed: {
		...mapGetters("voice", [
			"isCallDialogAttached",
			"activeCall",
			"draggableIsMoving"
		]),

		showDraggableCallDialog: {
			get() {
				return !this.isCallDialogAttached && this.activeCall;
			},

			set() {

			}
		}
	},

	watch: {
		isCallDialogAttached: {
			immediate: true,

			handler (isCallDialogAttached) {
				if (!isCallDialogAttached) {
					// Make call dialog draggable
					const activeDialogSelector = ".v-dialog.v-dialog--active";
					const wrappersSelector = ".v-dialog__content.v-dialog__content--active";

					this.resetPosition();

					document.addEventListener("mousedown", (event) => {
						this.makeDialogAbove(event, wrappersSelector);
						this.setStyles(event, activeDialogSelector);
					});

					document.addEventListener("mousemove", (event) => {
						this.moveDialog(event);
					});

					document.addEventListener("mouseup", (event) => {
						this.setTransitionBack(event);
					});
				}
			}
		}
	},

	methods: {
		...mapMutations("voice", ["setDraggableIsMoving"]),

		resetPosition() {
			this.container = {};
			const dialog = document.querySelector(".v-dialog");

			if (dialog) {
				dialog.style.inset = "unset";
				dialog.style.left = `calc(50%-${dialog.style.width/2}px)`;
				dialog.style.top = `calc(50%-${dialog.style.height/2}px)`;
			}
		},

		closestDialog(event, activeDialogSelector) {
			// check for left click
			if (event.button !== 0) {
				return;
			}

			let dialog;

			["v-card__title", "v-toolbar__content", "v-toolbar__title", "drag-icon"].forEach((className) => {
				if (event.target.classList.contains(className)) {
					dialog = event.target.closest(activeDialogSelector);
				}
			});

			if (!dialog?.classList.contains("v-dialog--persistent")) return;
			return dialog;
		},

		makeDialogAbove(event, wrappersSelector) {
			const wrappers = document.querySelectorAll(wrappersSelector);
			const activeWrapper = event.target.closest(wrappersSelector);

			// if we clicked on non-related element
			if (!activeWrapper) {
				return false;
			}

			// list of all z-indexes of wrappers
			let indexes = [];

			// collect all the indexes
			wrappers.forEach((element) => {
				indexes.push(parseInt(element.style.zIndex));
			});

			const maxIndex = Math.max(...indexes);
			const currentIndex = parseInt(activeWrapper.style.zIndex);

			// if z-index of current active dialog is less than we will switch them
			// to make this dialog above the rest
			if (currentIndex < maxIndex) {
				wrappers.forEach((element) => {
					if (parseInt(element.style.zIndex) === maxIndex) {
						element.style.zIndex = currentIndex.toString();
						activeWrapper.style.zIndex = maxIndex.toString();
					}
				});
			}
		},

		setStyles(event, activeDialogSelector) {
			const dialog = this.closestDialog(event, activeDialogSelector);

			if (dialog) {
				this.container.el = dialog;
				this.container.mouseStartX = event.clientX;
				this.container.mouseStartY = event.clientY;
				this.container.elStartX = this.container.el.getBoundingClientRect().left;
				this.container.elStartY = this.container.el.getBoundingClientRect().top;
				this.container.el.style.position = "fixed";
				this.container.oldTransition = this.container.el.style.transition;
				this.container.el.style.transition = "none";
			}
		},

		moveDialog(event) {
			if (this.container.el) {
				this.setDraggableIsMoving(true);
				this.container.el.style.left = Math.min(
					Math.max(this.container.elStartX + event.clientX - this.container.mouseStartX, 0),
					window.innerWidth - this.container.el.getBoundingClientRect().width
				) - 22 + "px";

				this.container.el.style.top = Math.min(
					Math.max(this.container.elStartY + event.clientY - this.container.mouseStartY, 0),
					window.innerHeight - this.container.el.getBoundingClientRect().height
				) - 25 + "px";
			} else {
				this.setDraggableIsMoving(false);
			}
		},

		setTransitionBack() {
			if (this.container.el) {
				this.container.el.style.transition = this.container.oldTransition;
				this.container.el = undefined;
			}
		}
	}
};
</script>

<style scoped>
::v-deep .v-dialog {
	position: absolute;
}
::v-deep .v-dialog--animated {
	animation: none;
}
</style>