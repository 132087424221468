var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      style: { border: "3px solid var(--v-primary-base)" },
      attrs: { rounded: "", color: _vm.backgroundColor },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "d-flex justify-center text-subtitle-1 white--text py-0",
          class: { "justify-center": _vm.isOnCall },
          staticStyle: { "background-color": "var(--v-primary-base)" },
        },
        [_vm._v(" " + _vm._s(_vm.callTitle) + " ")]
      ),
      _vm.forGroupLabel
        ? _c(
            "v-card-subtitle",
            { staticClass: "text-caption pt-4 pb-1" },
            [
              _vm._v(" " + _vm._s(_vm.forGroupLabel) + " "),
              _vm.forGroupLabel
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        bottom: "",
                        "min-width": "350",
                        "max-width": "350",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _vm.forGroupLabel
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        { attrs: { color: "primary", md: "" } },
                                        on
                                      ),
                                      [_vm._v(" mdi-information-slab-circle ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        707159380
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("for-group-tooltip", {
                              label: _vm.callDetails?.forGroupLabel,
                            })
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.fromGroupLabel
        ? _c("v-card-subtitle", { staticClass: "text-caption pt-4 pb-1" }, [
            _vm._v(" " + _vm._s(_vm.fromGroupLabel) + " "),
          ])
        : _vm._e(),
      _vm.forGroupLabel || _vm.fromGroupLabel
        ? _c("v-divider", { staticClass: "mx-4 my-0" })
        : _vm._e(),
      _c("call-info", { class: _vm.isOnCall ? "" : "pt-4" }),
      _vm.isOnCall ? _c("v-divider", { staticClass: "mx-4" }) : _vm._e(),
      _c("call-actions", {
        class: _vm.isOnCall ? "" : "pb-5",
        on: {
          showDialpad: function ($event) {
            _vm.showDialpad = !_vm.showDialpad
          },
          transfering: function ($event) {
            _vm.transfering = !_vm.transfering
          },
        },
      }),
      _vm.isOnCall && _vm.showDialpad
        ? _c("dialpad", { staticClass: "mt-n4" })
        : _vm._e(),
      !_vm.showDialpad && _vm.isCallDialogAttached
        ? _c("v-divider", { staticClass: "mt-n4" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }