<template>
	<modal
		v-model="showDialog"
		width="600"
		:title="$t('contact-us')"
		type="question"
		:is-cta-disable="isCtaDisable"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('send')"
		@cancel="closeDialog"
		@confirm="sendMessage"
	>
		<support-form
			v-model="valid"
			:form="form"
			:support-dialog="supportDialog"
			@updateValidation="updateValidation"
			@onDialogClose="supportDialog = false"
		/>
	</modal>
</template>

<script>
import { mapGetters } from "vuex";

import Modal from "@/components/common/Modal";
import { fullName } from "@/common/utils";

import SupportForm from "./SupportForm";

export default {
	name: "SupportDialog",
	components: { SupportForm, Modal },

	props: {
		value: {
			type: Boolean,
			default: false
		},

		fullPath: {
			type: String,
			default: undefined
		}
	},

	data() {
		return {
			valid: false,
			loading: false,
			form: {
				screenshot: undefined,
				description: undefined,
				name: undefined,
				email: undefined,
				url: undefined,
				user: undefined,
				organization: undefined
			},
			supportDialog: false
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),

		showDialog: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit("input", value);
			}
		},

		isCtaDisable() {
			return {
				cancel: this.loading,
				confirm: !this.valid
			};
		}
	},

	watch: {
		fullPath(newPath) {
			this.form.url = newPath;
		}
	},

	async mounted() {
		this.form.name = fullName(this.profile.name);
		this.form.email = this.profile.email;
		this.form.url = this.fullPath;
		this.form.user = this.profile.id;
		this.form.organization = this.profile.clinic;
	},

	methods: {
		async sendMessage() {
			this.loading = true;

			try {
				await this.axios.post("email/support", this.form);
				this.$success(this.$t("message-sent"));
				this.closeDialog();
			} catch (err) {
				this.$error(this.$t("error.send-support-request"));
			} finally {
				this.loading = false;
			}
		},

		closeDialog() {
			this.supportDialog = true;
			this.form = {
				...this.form,
				screenshot: undefined,
				description: undefined
			};
			this.$emit("closeDialog");
		},

		updateValidation(valid) {
			this.valid = valid;
		}
	}
};
</script>

<style scoped>
.content-height {
	height: 45vh;
	overflow-y: auto !important;
}
</style>
