import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
import fr from "vuetify/src/locale/pt.ts";

const vuetify = new Vuetify({
	theme: {
		options: { customProperties: true },
		themes: {
			dark: {
				accent: "#3D46C0",
				error: "#FF5252",
				google: "#CD4539",
				info: "#2196F3",
				primary: "#3C41C3",
				secondary: "#2F3540",
				success: "#4CAF50",
				warning: "#FFC107"
			},
			light: {
				accent: "#3D46C0",
				error: "#FF5252",
				google: "#CD4539",
				info: "#2196F3",
				primary: "#3C41C3",
				"light-primary": "#EBECF9",
				secondary: "#2F3540",
				success: "#4CAF50",
				warning: "#FFC107",
				initials: "#949ADB",
				avatar: "#6CD8CC",
				neutral: "#F3D24E",
				"call-green": "#1D9F1B",
				"call-red": "#FF2E00",
				"chat-primary": "#F5F6FF",
				"chat-secondary": "#008BF0",
				"info-gray": "#CFCFCF",
				"appointment-card-gray": "#8F8F8F",
				"satisfaction-warning": "#D5EE1A"
			}
		}
	},
	lang: {
		locales: { fr },
		current: "fr"
	}
});

Vue.use(Vuetify);

export default vuetify;

