import { getField, updateField } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { MICROSITE_URL } from "../App.vue";

export default {
	getters: {
		getField,

		allSections: state => {
			return state.allSections.sort((a, b) =>a.order - b.order);
		},

		allParentsArray: state => {
			return state.allParentsArray;
		},

		urlPreview:(state, getters, rootState) => {
			return `${MICROSITE_URL}/post?id=${rootState.login.profile.clinic}&post=${state.settingsModels.postId}`;
		},

		unsavedPosts: state => state.unsavedPosts
	},

	mutations: {
		updateField,
		setAllSections(state, sectionsFromDB) {
			state.allSections = sectionsFromDB;
		},

		appendNewSection(state, singleSection) {
			state.allSections.push({ ...singleSection });
		},

		appendNewSections(state, newSections) {
			state.allSections = [...state.allSections, ...newSections];
		},

		addNewSection(state, sectionId) {
			// Get the index of last root parent section
			const lastNumber = state.allSections[state.allSections.length - 1]?.index.split(".")[0];
			const parsedSectionIndex = parseInt(lastNumber) + 1;

			state.allSections.push({
				title: "",
				label: "",
				id: sectionId,
				disabled: false,
				subSections: [],
				parent: "root",
				checkbox: false,
				error: false,
				privateNote: undefined,
				index: parsedSectionIndex ? `${parsedSectionIndex}` : "1"
			});
		},

		deleteSection(state, index) {
			state.allSections.splice(index, 1);
		},

		resetSectionsState(state) {
			state.allSections = [];
		},

		resetPostSettings(state) {
			state.settingsModels.alias = undefined;
			state.settingsModels.expertise = undefined;
			state.settingsModels.postType = undefined;
			state.settingsModels.postId = undefined;
			state.settingsModels.flux = undefined;
			state.settingsModels.languages = "fr";
			state.settingsModels.title = undefined;
			state.settingsModels.thumbnail = undefined;
			state.settingsModels.summary = undefined;
			state.settingsModels.source = undefined;
			state.settingsModels.draft = false;
			state.settingsModels.country = undefined;
			state.settingsModels.locale = undefined;
			state.fieldsDisabled = false;
			state.newPost = true;
		},

		addNewSubsection(state, indexes) {
			const newSubsection = {
				title: undefined,
				body: undefined,
				url: undefined,
				align: "none",
				format: "none",
				id: uuidv4()
			};

			if (indexes.subsectionIndex === "top") {
				state.allSections[indexes.sectionIndex].subSections.unshift(newSubsection);
			} else {
				state.allSections[indexes.sectionIndex].subSections.splice(indexes.subsectionIndex + 1, 0, newSubsection);
			}
		},

		deleteSubsection(state, indexes) {
			state.allSections[indexes.sectionIndex].subSections.splice(indexes.subsectionIndex, 1);
		},

		changeSubsectionIndex(state, indexes) {
			const subsectionsArray = state.allSections[indexes.sectionIndex].subSections;

			if (indexes.direction === "up") {
				subsectionsArray.splice(indexes.subsectionIndex - 1, 0, subsectionsArray.splice(indexes.subsectionIndex, 1)[0]);
			} else {
				subsectionsArray.splice(indexes.subsectionIndex + 1, 0, subsectionsArray.splice(indexes.subsectionIndex, 1)[0]);
			}
		},

		changeSectionIndex(state, indexes) {
			if (indexes.direction === "up") {
				state.allSections.splice(indexes.sectionIndex - 1, 0, state.allSections.splice(indexes.sectionIndex, 1)[0]);
			} else {
				state.allSections.splice(indexes.sectionIndex + 1, 0, state.allSections.splice(indexes.sectionIndex, 1)[0]);
			}
			state.allSections.forEach(section => section.order = 0);
		},

		addParentToArray(state, parent) {
			state.allParentsArray.push(parent);
		},

		setNewParentsArray(state, parents) {
			state.allParentsArray = parents;
		},

		changePostLocale(state, locale) {
			state.settingsModels.locale = locale;
		},

		setUnsavedPosts: (state, payload) => state.unsavedPosts.push(payload),

		resetUnsavedPosts: state => state.unsavedPosts = [],

		updateAllSections: (state, payload) => state.allSections = payload
	},
	actions: {
		async savePost({ state, rootState }, method) {
			const parsedClinic = state.settingsModels.clinicId ? state.settingsModels.clinicId : "";

			const newPost = {
				locale: state.settingsModels.locale || `${state.settingsModels.languages}-${state.settingsModels.country}`,
				type: state.settingsModels.postType,
				alias: state.settingsModels.alias,
				title: state.settingsModels.title,
				image: state.settingsModels.thumbnail,
				automation: "twilio automation id",
				short: state.settingsModels.summary,
				clinic: parsedClinic,
				author: rootState.login.profile.id,
				draft: state.settingsModels.draft,
				services: [ state.settingsModels.expertise ]
			};

			if (method === "create") {
				state.settingsModels.postId = (await axios.post("posts", newPost)).data.id;
			} else {
				const updatePost = {
					...newPost,
					updatedBy: rootState.login.profile.id
				};

				delete updatePost.author;
				await axios.put(`posts/${state.settingsModels.postId}`, updatePost);
			}

			state.fieldsDisabled = true;
			state.newPost = false;
			state.unsavedChanges = false;
		},

		async getSections({ commit }, arg) {
			const sections = (await axios.get(`posts/${arg.targetedPostID}/sections?locale=${arg.postLocale}`)).data;
			const orderedSections = sections.sort((a, b) => a.order - b.order);

			orderedSections.forEach(section => {
				commit("addParentToArray", {
					id: section.id,
					label: section.label
				});
			});

			orderedSections.forEach(section => section.error = false);
			if (!arg.method) commit("appendNewSections", orderedSections);
		},

		createIndexation({ state }) {
			let objectsLabels = {}, parentsIterators = {}, rootCounter = 1;

			for (const tempSection of state.allSections) {
				if (tempSection.parent === "root") {
					objectsLabels[tempSection.id] = `${rootCounter}`;
					rootCounter++;
				} else {
					// Keep concatening child string with the parent string
					if (!parentsIterators[tempSection.parent]) {
						parentsIterators[tempSection.parent] = 1;
					} else {
						parentsIterators[tempSection.parent]++;
					}

					objectsLabels[tempSection.id] = `${objectsLabels[tempSection.parent]}.${parentsIterators[tempSection.parent]}`;
				}
				tempSection.index = objectsLabels[tempSection.id];
			}
		},

		async deleteSection({ state }, id) {
			await axios.delete(`posts/${state.settingsModels.postId}/sections/${id}`);
		}
	},
	namespaced: true,
	state: () => ({
		settingsModels: {
			alias: undefined,
			expertise: "",
			clinicId: undefined,
			postType: undefined,
			postId: undefined,
			flux: undefined,
			languages: "fr",
			title: undefined,
			thumbnail: undefined,
			summary: undefined,
			source: undefined,
			draft: false,
			country: undefined,
			locale: undefined,
			privateNote: undefined
		},
		allSections: [],
		fieldsDisabled: false,
		settingsFormValid: false,
		newPost: true,
		openedPanels: [],
		confirmationDialog: false,
		inConsultation: false,
		allParentsArray: [{ id: "root", label: "Aucune" }],
		unsavedChanges: false,
		allConsultationDataLoaded: false,
		unsavedPosts: []
	})
};
