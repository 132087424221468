var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("support-dialog", {
        attrs: { "full-path": `${_vm.apiUrl}${_vm.$route.fullPath}` },
        on: {
          closeDialog: function ($event) {
            _vm.supportDialog = false
          },
        },
        model: {
          value: _vm.supportDialog,
          callback: function ($$v) {
            _vm.supportDialog = $$v
          },
          expression: "supportDialog",
        },
      }),
      _c("logout-dialog", {
        model: {
          value: _vm.logoutDialog,
          callback: function ($$v) {
            _vm.logoutDialog = $$v
          },
          expression: "logoutDialog",
        },
      }),
      _vm.connected && _vm.profile.clinic
        ? _c(
            "v-navigation-drawer",
            {
              attrs: {
                dark: "",
                color: "secondary",
                app: "",
                permanent: "",
                temporary: !_vm.mini,
                "expand-on-hover": "",
                "mini-variant": _vm.mini,
              },
              on: {
                "update:miniVariant": function ($event) {
                  _vm.mini = $event
                },
                "update:mini-variant": function ($event) {
                  _vm.mini = $event
                },
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "div",
                { staticClass: "full-height d-flex flex-column" },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "mr-3" },
                            [
                              _c("v-img", {
                                attrs: {
                                  "max-width": "24",
                                  src: require("@/assets/img/icon-fresk-dark.svg"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "d-flex" },
                            [
                              !_vm.mini
                                ? _c(
                                    "v-list-item-title",
                                    { staticClass: "logo-image" },
                                    [
                                      _c("img", {
                                        staticClass: "ml-3",
                                        attrs: {
                                          "max-width": "100",
                                          src: require("@/assets/img/logo-white.svg"),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("navigation-list", {
                    staticClass: "flex-grow-1",
                    attrs: {
                      items: _vm.filterBySector(_vm.items.top),
                      notification: _vm.hasPendingNotifications,
                    },
                  }),
                  _c("v-divider"),
                  _c("navigation-list", {
                    attrs: { items: _vm.filterBySector(_vm.items.bottom) },
                    on: {
                      "click:reportProblem": function ($event) {
                        _vm.supportDialog = true
                      },
                      "click:logout": _vm.logoutUser,
                      "click:helpCenter": _vm.goToHelpCenter,
                      "click:supportTalk": _vm.callToSupportCenter,
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }