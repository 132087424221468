var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-badge",
    {
      staticClass: "badge-container",
      attrs: {
        bordered: "",
        bottom: "",
        right: "",
        "offset-x": _vm.size * 0.25,
        "offset-y": _vm.size * -0.07,
      },
      scopedSlots: _vm._u([
        {
          key: "badge",
          fn: function () {
            return [_c("v-icon", [_vm._v("mdi-domain")])]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.business,
        callback: function ($$v) {
          _vm.business = $$v
        },
        expression: "business",
      },
    },
    [
      _vm.sentiment && !_vm.anonymous
        ? _c(
            "v-sheet",
            { staticClass: "position-absolute above emoji-wrapper" },
            [
              _c("sentiment-emoji", {
                staticClass: "antialias",
                attrs: { size: _vm.size / 4, sentiment: _vm.sentiment },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-tooltip",
        {
          attrs: { disabled: !_vm.goToConversation, top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-avatar",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "avatar-btn",
                          attrs: { color: _vm.color, size: _vm.size },
                          on: { click: _vm.showConversation },
                        },
                        "v-avatar",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm.initials
                        ? _c(
                            "span",
                            {
                              staticClass: "white--text font-weight-bold",
                              style: { fontSize: _vm.size / 3 + "px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: { color: "white", size: _vm.size * 0.75 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.anonymous
                                      ? "mdi-incognito"
                                      : "mdi-account"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("show-conversation")))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }