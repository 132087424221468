var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { nav: "" } },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "v-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !item.disabled,
              expression: "!item.disabled",
            },
          ],
          key: index,
          class: { "have-sub-menu": item.submenu },
          attrs: { link: "", to: item.route, ripple: !item.submenu },
          on: {
            click: function ($event) {
              item.submenu
                ? _vm.toggleSubmenu(item.id)
                : _vm.$emit(`click:${item.id}`)
            },
          },
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "mr-2" },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    value: index === 0 && _vm.notification,
                    color: "red",
                    overlap: "",
                    dot: "",
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" " + _vm._s(item.icon) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            {
              class: {
                "mt-1 ml-n1": _vm.isSubmenuOpen(item.id),
                "mt-0 ml-2": !_vm.isSubmenuOpen(item.id),
              },
            },
            [
              _c(
                "v-list-item-title",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c(
                    "span",
                    {
                      class: {
                        "pl-2": _vm.isSubmenuOpen(item.id),
                        "ml-0": !_vm.isSubmenuOpen(item.id),
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t(item.title, item.titleParams)) + " "
                      ),
                    ]
                  ),
                  item.submenu
                    ? _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isSubmenuOpen(item.id)
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.isSubmenuOpen(item.id)
                ? _c(
                    "v-list",
                    { staticClass: "mb-n4" },
                    _vm._l(item.submenu, function (subItem, subIndex) {
                      return _c(
                        "v-list-item",
                        {
                          key: subIndex,
                          attrs: { link: "", to: subItem.route },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(`click:${subItem.id}`)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v(_vm._s(subItem.icon))])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "ml-n6" },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "small-font" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t(subItem.title)) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }