<template>
	<v-container>
		<slot />
		<v-row>
			<v-col>
				<slot name="left" />
			</v-col>
			<v-col>
				<slot name="right" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: "TwoColumns"
};
</script>
