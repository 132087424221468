var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": _vm.modalMaxWidth,
        persistent: _vm.isPersistent,
        width: _vm.modalWidth,
      },
      on: {
        "click:outside": function ($event) {
          _vm.isPersistent ? () => {} : _vm.handleClose()
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "py-6 px-3" },
        [
          !_vm.hideHeader
            ? _c(
                "v-row",
                {
                  staticClass: "flex flex-nowrap",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "py-0 fill-height text-truncate" },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass:
                            "text-h6 font-weight-bold py-0 pl-0 title text-truncate",
                        },
                        [
                          _vm.modalTitle
                            ? _c("span", { class: _vm.titleClass }, [
                                _vm._v(_vm._s(_vm.modalTitle)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm.modalSubTitle
                        ? _c("v-card-subtitle", { staticClass: "pa-0" }, [
                            _vm._v(" " + _vm._s(_vm.modalSubTitle) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._t("sentTime"),
                  _vm.modalType !== "question" || _vm.showCloseButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            icon: "",
                            color: "secondary",
                            disabled: _vm.loading,
                          },
                          on: { click: _vm.handleClose },
                        },
                        [
                          _c("v-icon", { attrs: { size: "30" } }, [
                            _vm._v(" mdi-close "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _vm._t("details"),
              !_vm.hideHeader
                ? _c("v-divider", { staticClass: "mb-4 mt-2 mx-4" })
                : _vm._e(),
              _vm.modalLoading
                ? _c(
                    "v-sheet",
                    {
                      staticClass:
                        "d-flex flex-column justify-center align-center py-12",
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 50,
                          width: 7,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : [
                    _vm._t("default"),
                    !_vm.isHideCta
                      ? _c(
                          "v-card-actions",
                          { staticClass: "pa-0 px-4" },
                          [
                            !!_vm.modalCancelText
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-4 ml-auto",
                                    attrs: {
                                      color: "error",
                                      disabled: _vm.isCancelDisable,
                                      loading: _vm.isCancelLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("cancel")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.modalCancelText) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.showSpacer ? _c("v-spacer") : _vm._e(),
                            !!_vm.modalExtraText
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-4",
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.isExtraDisable,
                                      loading: _vm.isExtraLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("extra")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.modalExtraText) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            !!_vm.modalConfirmText
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-4",
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.isConfirmDisable,
                                      loading: _vm.isConfirmLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("confirm")
                                      },
                                    },
                                  },
                                  [
                                    _vm.saveIcon
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { color: "white" },
                                          },
                                          [_vm._v(" mdi-content-save ")]
                                        )
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.modalConfirmText) + " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._t("close"),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }