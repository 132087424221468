var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("alerts"),
      _c("env"),
      _c("call-draggable"),
      _vm.openDisconnectDialog
        ? _c(
            "modal",
            {
              attrs: {
                width: "600",
                "confirm-text": _vm.$t("i-understand"),
                persistent: true,
                title: _vm.$t("force-disconnect"),
                type: "question",
              },
              on: { confirm: _vm.handleSessionDisconnected },
              model: {
                value: _vm.openDisconnectDialog,
                callback: function ($$v) {
                  _vm.openDisconnectDialog = $$v
                },
                expression: "openDisconnectDialog",
              },
            },
            [
              _c("v-card-text", { staticClass: "text-body-1" }, [
                _vm._v(" " + _vm._s(_vm.$t("disconnect-info")) + " "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "text-body-1 font-weight-bold" },
                [_vm._v(" " + _vm._s(_vm.$t("disconnect-hint")) + " ")]
              ),
              _c("v-divider", { staticClass: "mb-4" }),
            ],
            1
          )
        : _vm._e(),
      _c("microphone-guide-dialog"),
      _vm.connected ? _c("ios-notification-dialog") : _vm._e(),
      _vm.connected ? _c("email-disconnected-dialog") : _vm._e(),
      _c("status-overlay", { attrs: { status: _vm.status } }),
      _vm.nav
        ? _c("navigation-menu", {
            on: {
              mini: function ($event) {
                _vm.mini = true
              },
              expanded: function ($event) {
                _vm.mini = false
              },
            },
          })
        : _vm._e(),
      _c(
        "v-main",
        { class: { pad: !_vm.mini } },
        [
          _c("status-banner", { attrs: { status: _vm.status } }),
          _c("router-view", { key: _vm.$route.path }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }