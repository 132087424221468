export default {
	getters: {
		getLocaleFilter: state => {
			return state.localeFilter;
		}
	},

	mutations: {
		setLocaleFilter(state, locale) {
			state.localeFilter = locale;
		}
	},

	namespaced: true,
	state: () => ({
		localeFilter: undefined
	})
};
