<template>
	<modal
		v-model="showDialog"
		:title="$t('transfer-call')"
		:sub-title="showSipList ? $t('transfer-group-user-info') : $t('transfer-info')"
		width="600"
		:type="'question'"
		:cancel-text="$t('cancel')"
		:confirm-text="$t('transfer-call')"
		:confirm-loading="confirmLoading"
		:confirm-disabled="confirmDisabled"
		:extra-disabled="extraDisabled"
		:extra-text="extraButtonText"
		@cancel="$emit('cancel')"
		@confirm="transferCall(group)"
		@extra="onExtraButton"
	>
		<div v-if="!showSipList">
			<v-list rounded class="py-0 user-container mb-8">
				<v-list-item-group v-model="group" color="primary">
					<v-list-item v-for="userGroup of groups" :key="userGroup.id" :value="userGroup">
						<v-list-item-avatar color="initials">
						<span class="white--text">
							{{ userGroup.label[0] }}
						</span>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>
								{{ `${userGroup.label} ${groupPhoneNumber(userGroup.phone)}` }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</div>

		<div v-if="showSipList">
			<v-list class="py-0 user-container mb-8 px-4">
				<v-row v-if="showSipList">
					<v-col v-for="(userGroupSip) in groupUsers" :key="userGroupSip?.displayName" cols="6">
						<v-card
						elevation="2"
						class="rounded"
						>
							<v-list-item
								:value="userGroupSip"
								:class="{ 'selected': userSip === userGroupSip }"
								@click="userSip = userGroupSip"
							>
								<v-list-item-avatar color="initials">
									<v-icon class="white--text">mdi-phone-classic</v-icon>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">
										{{ `${userGroupSip.displayName}` }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-card>
					</v-col>
				</v-row>
			</v-list>
		</div>
</modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/common/Modal";
import { RequiredValidator } from "@/validators";
import { phoneNumberFormater } from "../../common/utils";

export default {
	name: "TransferCall",

	components: {
		Modal
	},

	props: {
		groups: {
			type: Array,
			default: () => []
		},

		open: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			group: null,
			userSip: null,
			loading: false,
			showSipList: false,
			rules: {
				required: RequiredValidator
			},
			selectedUserGroupIndex: null,
			showDialog: this.open
		};
	},

	computed: {
		...mapGetters("conversation", ["currentParticipant"]),

		confirmDisabled() {
			return !this.group;
		},

		extraDisabled() {
			return !(this.groupUsers?.length);
		},

		extraButtonText() {
			return this.showSipList ? this.$t("back-to-groups") : this.$t("select-user");
		},

		confirmLoading() {
			return this.loading;
		},

		groupPhoneNumber() {
			return (pn) => phoneNumberFormater(pn);
		},

		groupUsers() {
			if (this.group && Array.isArray(this.group.users)) {
				return this.group.users.filter(user => user !== null && user.displayName && user.displayName.trim() !== "" );
			}

			return [];
		}
	},

	watch: {
		showDialog: {
			deep: true,

			handler() {
				this.loading = false;
			}
		}
	},

	methods: {
		transferCall(group) {
			this.loading = true;
			this.$emit("confirm", { group: group, userSip: this.userSip?.sip });
		},

		onExtraButton() {
			if (this.userSip) {
				this.userSip = null;
			}

			this.showSipList = !this.showSipList;
		}
	}
};
</script>

<style scoped>
.selected {
	border: 2px solid var(--v-primary-base);
	background-color: var(--v-light-primary-base);
}
</style>
