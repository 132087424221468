var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status?.enabled && _vm.status?.banner
    ? _c(
        "v-banner",
        {
          attrs: {
            color:
              _vm.statusColorEnum[_vm.statusEnum.indexOf(_vm.status.status)],
            dark: "",
            elevation: "5",
            icon: "mdi-wrench",
            sticky: "",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function ({ dismiss }) {
                  return [
                    _vm.status.dismissable
                      ? _c("v-btn", { on: { click: dismiss } }, [
                          _vm._v(" " + _vm._s(_vm.$t("close")) + " "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ],
            null,
            false,
            3519035314
          ),
        },
        [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.status.bannerText) + " "),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }