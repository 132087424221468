import moment from "moment";
import axios from "axios";
import store from "@/store";
import { DequeueCallException, EnqueueCallException } from "./exceptions";

export const QUEUE_ACTION_ENQUEUE = "enqueue";
export const QUEUE_ACTION_DEQUEUE = "dequeue";
export const QUEUE_ACTION_CLEAR = "clear";

class PhoneCall {
	callDetails = null;
	recordingSid = null;
	_duration = 0;
	_onHold = false;
	direction = null;

	constructor(callInstance, inbound, parameters) {
		this.callInstance = callInstance;

		this.callDetails = {
			...(callInstance.customParameters ? Object.fromEntries(callInstance.customParameters) : {})
		};

		if (inbound) {
			this.callDetails.phoneNumber = callInstance.parameters?.From;
		} else {
			this.callDetails.phoneNumber = parameters?.To;
		}

		store.commit("voice/setCallDetails", this.callDetails);
	};

	mute = (muteToggle) => {
		this.callInstance?.mute(muteToggle);
	};

	countDuration = () => {
		if (durationTimer) clearInterval(durationTimer);
		var durationTimer = setInterval(() => {
			this._duration++;
		}, 1000);
	};

	onCall =  () => {
		store.commit("voice/setIsOnCall", true);
	};

	answered = (interval) => {
		if (interval) {
			clearInterval(interval);
		}
		store.commit("voice/acceptCall");
	};

	accept = () => {
		this.callInstance?.accept();
	};

	hangUp = () => {
		this.callInstance?.disconnect();
		if (this.callDetails?.patientCallSid) {
			store.commit("queue/releaseCall", this.callDetails.patientCallSid);
		}
	};

	reject = () => {
		this.callInstance?.reject();
		if (this.callDetails?.patientCallSid) {
			store.commit("queue/releaseCall", this.callDetails.patientCallSid);
		}
	};

	rejectAll = () => {
		this.callInstance?.reject();
		if (this.callDetails?.patientCallSid) {
			store.commit("queue/releaseCall", this.callDetails.patientCallSid);
		}

		if (this.direction === "inbound") {
			// Reject all other calls
			try {
				axios.post("voice/v1/calls/rejectAll", {
					callSid: this.callSid
				});
			} catch (error) {
				console.error(error);
			}
		}
	};

	resume = async () => {
		try {
			this._onHold = false;
			this.mute(false);

			await axios.post("voice/v1/calls/resume", {
				callSid: this.callSid
			});
		} catch (err) {
			throw new DequeueCallException(`Failed to resume call: ${this.callSid}`);
		}
	};

	hold = async () => {
		try {
			this._onHold = true;
			this.mute(true);

			await axios.post("voice/v1/calls/hold", {
				callSid: this.callSid
			});
		} catch (err) {
			this._onHold = false;
			this.mute(false);
			throw new EnqueueCallException(`Failed to enqueue call ${this.callSid}`);
		}
	};

	async transferCall(group, userSip) {
		try {
			await axios.post("voice/v1/calls/transfer", {
				callSid: this.callSid,
				to: userSip ? userSip : group.phone,
				toUserGroup: group.id
			});
		} catch (error) {
			console.error(error);
			this.$error(this.$t("error.transfer-call"));
		}
	}

	async sendToQueue() {
		try {
			if (this.callDetails?.patientCallSid) {
				await axios.post(`voice/v1/queues/send/${this.callDetails.patientCallSid}`);
			}
		} catch (error) {
			this.$error(this.$t("error.send-call-to-queue"));
		}
	}

	get duration() {
		return moment.utc(this._duration * 1000).format("mm:ss");
	}

	get status() {
		return this.callInstance?.status();
	}

	get onHold() {
		return this._onHold;
	}

	get callSid() {
		return this.callInstance?.parameters?.CallSid;
	}
}

export default PhoneCall;
