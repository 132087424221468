var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "", disabled: _vm.isInbound && _vm.isHavingCall },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g({}, on),
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        elevation: "0",
                        color: "primary",
                        large: !_vm.isInbound,
                        small: _vm.isInbound,
                        disabled:
                          _vm.isDisabledByPermission || !_vm.isCallAvailable,
                        outlined: !_vm.isCallAvailable,
                      },
                      on: { click: _vm.handleCall },
                    },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v(" " + _vm._s(_vm.icon) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("span", { staticClass: "wrapped-text" }, [
        _vm._v(_vm._s(_vm.buttonText)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }