<template>
	<v-sheet
		rounded
		:color="backgroundColor"
		:style="{ border: '3px solid var(--v-primary-base)' }"
	>
		<v-card-title
			class="d-flex justify-center text-subtitle-1 white--text py-0"
			style="background-color: var(--v-primary-base)"
			:class="{ 'justify-center': isOnCall }"
		>
			{{ callTitle }}
		</v-card-title>
		<v-card-subtitle v-if="forGroupLabel" class="text-caption pt-4 pb-1">
			{{ forGroupLabel }}
			<v-tooltip v-if="forGroupLabel" bottom min-width="350" max-width="350">
				<template #activator="{ on }">
					<v-icon v-if="forGroupLabel" color="primary" md v-on="on">
						mdi-information-slab-circle
					</v-icon>
				</template>
				<span>{{ $t("for-group-tooltip", { label: callDetails?.forGroupLabel }) }}</span>
			</v-tooltip>
		</v-card-subtitle>
		<v-card-subtitle v-if="fromGroupLabel" class="text-caption pt-4 pb-1">
			{{ fromGroupLabel }}
		</v-card-subtitle>
		<v-divider v-if="forGroupLabel || fromGroupLabel" class="mx-4 my-0" />
		<call-info :class="isOnCall ? '' : 'pt-4'" />

		<v-divider v-if="isOnCall" class="mx-4" />

		<call-actions
			:class="isOnCall ? '' : 'pb-5'"
			@showDialpad="showDialpad = !showDialpad"
			@transfering="transfering = !transfering"
		/>

		<dialpad v-if="isOnCall && showDialpad" class="mt-n4" />

		<v-divider v-if="!showDialpad && isCallDialogAttached" class="mt-n4" />
	</v-sheet>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CallInfo from "./CallInfo.vue";
import CallActions from "./CallActions.vue";
import Dialpad from "../Dialpad.vue";

export default {
	name: "CallDetails",

	components: {
		CallInfo,
		CallActions,
		Dialpad
	},

	data() {
		return {
			showDialpad: false,
			transfering: false
		};
	},

	computed: {
		...mapGetters("voice", [
			"isCallDialogAttached",
			"activeCall",
			"callDetails",
			"isOnCall"
		]),

		...mapGetters("conversation", ["currentParticipant"]),
		...mapMutations("conversation", ["setPrepareToCallLoader"]),

		callTitle() {
			if (!this.activeCall) {
				return "";
			}

			if (
				this.activeCall?.duration === "00:00" &&
				this.activeCall?.direction === "outbound"
			) {
				return this.$t("calling");
			}

			if (this.callDetails?.transfer === "true") {
				return this.$t("transfered-call");
			}

			if (this.activeCall?.direction === "outbound") {
				return this.$t("outbound-call");
			}

			return this.$t("incoming-call");
		},

		forGroupLabel() {
			// Check if incoming call
			if (this.activeCall?.direction === "inbound") {
				// Check if data exists before display UI
				const details = this.callDetails;

				if (this.currentParticipant.identity && details?.forGroupId) {
					return this.currentParticipant.identity === details?.forGroupId ? null : this.$t("for-group", { label: details?.forGroupLabel });
				}
			}
			return null;
		},

		fromGroupLabel() {
			if (this.callDetails?.fromGroupLabel?.length) {
				return this.$t("from-group", { label: this.callDetails?.fromGroupLabel });
			}
			return null;
		},

		backgroundColor() {
			return this.isOnCall ? "var(--v-light-primary-base)" : "";
		}
	},

	mounted() {
		this.$store.commit("conversation/setPrepareToCallLoader", false);
	}
};
</script>

<style scoped>
.drag-icon {
	cursor: grab;
}

</style>
