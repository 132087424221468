<template>
	<v-menu v-if="devMode">
		<template #activator="{ on: menu, attrs }">
			<v-btn
				fab
				fixed
				bottom
				right
				x-small
				:color="color"
				v-bind="attrs"
				v-on="menu"
			>
				<v-icon>mdi-flag</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-card-title>{{ $t(`env.${env}`) }}</v-card-title>

			<v-card-text class="d-flex flex-column row-gap">
				<v-btn :loading="syncing" color="primary" @click="syncConfiguration">
					<v-icon left dark>
						mdi-phone-classic
					</v-icon>

					{{ $t("env.redirect-webhooks") }}
				</v-btn>

				<v-btn color="secondary" :href="REPOSITORY_URL" target="_blank">
					<v-icon left dark>
						mdi-github
					</v-icon>

					{{ $t("env.github") }}
				</v-btn>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { REPOSITORY_URL } from "@/common/const";
import { devMode } from "@/common/utils";

export default {
	name: "Env",

	data() {
		return {
			devMode: devMode(),
			syncing: false,
			REPOSITORY_URL,
			env: process.env.NODE_ENV
		};
	},

	computed: {
		...mapGetters("login", ["profile", "connected"]),

		color() {
			return this.env === "rc" ? "warning" : "info";
		}
	},

	methods: {
		...mapMutations("alerts", { showAlert: "add" }),

		async syncConfiguration() {
			try {
				this.syncing = true;
				await this.axios.post("conversations/synchronize");
				await this.axios.post("conversations/flows/synchronize");
				await this.axios.post("voice/v1/synchronize");

				this.showAlert({
					type: "success",
					message: this.$t("sync-success"),
					timeout: true
				});
			} catch (err) {
				this.showAlert({
					type: "error",
					message: this.$t("error.sync"),
					timeout: true
				});
			} finally {
				this.syncing = false;
			}
		}
	}
};
</script>
