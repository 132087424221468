<template>
	<modal
		v-model="showDialog"
		:width="modalWidth"
		:title="$t('activate-microphone')"
		@close="setIsMicroGuideOpen(false)"
		@persistent="setIsMicroGuideOpen(false)"
	>
		<v-card-text class="px-0 text-subtitle-1 pt-0 px-4">
			{{ $t("microphone-instruction") }}
		</v-card-text>

		<v-card flat class="px-4">
			<v-img src="@/assets/img/notifications/micro-prompt.png" />
		</v-card>
	</modal>
</template>

<script>
import Modal from "@/components/common/Modal";
import { mapGetters, mapMutations } from "vuex";

export default {
	name: "MicrophoneGuideDialog",
	components: { Modal },

	computed: {
		...mapGetters("voice", ["isMicroGuideOpen"]),

		dialog: {
			get() {
				return this.isMicroGuideOpen;
			},

			set(newVal) {
				this.isMicroGuideOpen = newVal;
				return newVal;
			}
		},

		modalWidth() {
			return this.$vuetify.breakpoint.lgAndUp ? "40%" : "45%";
		},

		showDialog: {
			get() {
				return this.isMicroGuideOpen;
			},

			set(value) {
				this.$emit("input", value);
			}
		}
	},

	watch: {
		isMicroGuideOpen(newVal) {
			if (newVal) {
				this.$store.commit("conversation/setPrepareToCallLoader", false);
			}
		}
	},

	methods: {
		...mapMutations("voice", ["setIsMicroGuideOpen"])
	}
};
</script>
