<template>
	<v-app>
		<alerts />
		<env />
		<call-draggable />

		<modal
			v-if="openDisconnectDialog"
			v-model="openDisconnectDialog"
			width="600"
			:confirm-text="$t('i-understand')"
			:persistent="true"
			:title="$t('force-disconnect')"
			:type="'question'"
			@confirm="handleSessionDisconnected"
		>
			<v-card-text class="text-body-1">
				{{ $t('disconnect-info') }}
			</v-card-text>
			<v-card-text class="text-body-1 font-weight-bold">
				{{ $t('disconnect-hint') }}
			</v-card-text>
			<v-divider class="mb-4" />
		</modal>

		<microphone-guide-dialog />
		<ios-notification-dialog v-if="connected" />
		<email-disconnected-dialog v-if="connected" />
		<status-overlay :status="status" />
		<navigation-menu v-if="nav" @mini="mini = true" @expanded="mini = false" />

		<v-main :class="{ 'pad': !mini }">
			<status-banner :status="status" />
			<router-view :key="$route.path" />
		</v-main>
	</v-app>
</template>

<script>
import Vue from "vue";
import { bus } from "@/main.js";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import NavigationMenu from "./components/navigation/NavigationMenu";
import { handleLogoutEvent } from "./composables/auth";
import useConversation from "@/composables/conversation";
import { ALERT_HIDDEN, ALERT_SHOWN, ALERT_BLOCKED, CALL_STATUS } from "@/common/const";
import messaging from "./composables/messaging";
import useVoice from "@/composables/voice";
import CallDraggable from "./components/conversations/call-dialog/CallDraggable.vue";
import MicrophoneGuideDialog from "./components/conversations/call-dialog/MicrophoneGuideDialog.vue";
import IosNotificationDialog from "./components/conversations/chat-box/IosNotificationDialog.vue";
import EmailDisconnectedDialog from "./components/conversations/chat-box/EmailDisconnectedDialog.vue";
import StatusBanner from "./components/support/StatusBanner.vue";
import StatusOverlay from "./components/support/StatusOverlay.vue";
import Modal from "./components/common/Modal.vue";

export const API_URL = process.env.VUE_APP_API_URL;
export const APP_URL = process.env.VUE_APP_URL;
export const FRENCH_PHONE_NUMBER = process.env.VUE_APP_FRENCH_PHONE_NUMBER;
export const VUE_APP_FRESK_PRIVACY_POLICY_URL = process.env.VUE_APP_FRESK_PRIVACY_POLICY_URL;
export const VUE_APP_FRESK_TERMS_OF_USE_URL = process.env.VUE_APP_FRESK_TERMS_OF_USE_URL;
export const MICROSITE_URL = process.env.VUE_APP_MICROSITE_URL;
export const LOGIN_PATH = "login";
export const AUDIO_DEVICE_NAME = "audioinput";

const conversationsHook = useConversation();

Vue.mixin({
	computed: {
		...mapFields("conversation", ["showNotificationsAlert"]),
		...mapFields("voice", ["showIncomingCallAlert"]),

		displayNotificationSettings() {
			return (isAlert) => {
				const isIos = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

				if (isAlert) {
					return !isIos && this.showNotificationsAlert === ALERT_SHOWN && this.$vuetify.breakpoint.mdAndUp;
				}

				return !isIos && this.$vuetify.breakpoint.mdAndUp;
			};
		},

		displayIncomingCallSettings() {
			return (isAlert) => {
				const isIos = /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

				if (isAlert) {
					return !isIos && this.showIncomingCallAlert === ALERT_SHOWN && this.$vuetify.breakpoint.mdAndUp;
				}

				return !isIos && this.$vuetify.breakpoint.mdAndUp;
			};
		}
	}
});

const MIN_WIDTH = 1024;

export default {
	name: "App",

	components: {
		StatusBanner,
		StatusOverlay,
		NavigationMenu,
		CallDraggable,
		MicrophoneGuideDialog,
		IosNotificationDialog,
		EmailDisconnectedDialog,
		Modal
	},

	data() {
		return {
			mini: true,
			timers: {},
			openDisconnectDialog: false,
			status: {}
		};
	},

	metaInfo() {
		if (process.env.NODE_ENV === "production") {
			return {
				meta: [{ name: "google-site-verification", content: "U3DXAYfupUBb8_5KI0A3JQIRdgCX0R7xeIeJ5jBa59E" }]
			};
		}
	},

	computed: {
		...mapState("ui", ["nav", "menu", "fullScreen"]),
		...mapGetters("login", ["profile", "connected"]),
		...mapGetters("clinic", ["clinicData", "country"]),
		...mapGetters("conversation", ["hasPendingNotifications", "currentGroup"]),
		...mapGetters("voice", ["hasIncomingCall", "isOnCall", "isMicroOn", "activeCall"]),

		...mapFields("conversation", [
			"browserNotificationsDisabled",
			"desktopNotificationsEnabled",
			"showNotificationsAlert"
		]),

		...mapFields("voice", [
			"showIncomingCallAlert"
		])
	},

	watch: {
		hasPendingNotifications: {
			immediate: true,

			handler(hasNotifications) {
				this.handleNotifications(hasNotifications, 5000, 2000, "Fresk 💬", "Fresk", "Nouveau message", "newMessageTimer");
			}
		},

		profile: {
			immediate: true,

			async handler(val) {
				if (!this.connected) return;

				if (!this.country) {
					await this.$store.dispatch("clinic/getClinicData");
				}

				if (this.country == "FR") {
					messaging.bootstrap();
				} else {
					conversationsHook.bootstrap(this.currentGroup || val?.defaultGroup);
					useVoice.bootstrap();
				}

				this.$i18n.locale = val?.locale || this.$i18n.fallbackLocale;
				this.$root.$i18n.locale = this.$i18n.locale;
			}
		},

		hasIncomingCall: {
			immediate: true,

			handler(hasIncomingCall) {
				this.handleNotifications(hasIncomingCall, 5000, 2000, "Fresk 📲", "Fresk", "Nouvel appel", "incomingCallTimer");
			}
		},

		isOnCall: {
			immediate: true,

			async handler(isOnCall) {
				if (isOnCall) {
					if (this.isMicroOn) {
						this.enableMicrophone();
					}
				} else {
					if (this.isMicroOn) {
						this.disableMicrophone();
					}
				}
			}
		},

		activeCall: {
			immediate: true,

			handler(activeCall) {
				if (
					activeCall?.callInstance.status() === CALL_STATUS.PENDING &&
					!this.connected
				) {
					activeCall.reject();
				}
			}
		}
	},

	async created() {
		window.addEventListener("beforeunload", async () => {
			if (this.country == "FR") {
				return messaging.cleanup();
			}

			useVoice.cleanup();

			this.setCurrentParticipant(null);
			await conversationsHook.cleanup();
		});

		if (this.showNotificationsAlert !== ALERT_BLOCKED) {
			this.showNotificationsAlert = ["denied", "default"].includes(Notification.permission)
				? ALERT_SHOWN
				: ALERT_HIDDEN;
		}

		if (this.showIncomingCallAlert !== ALERT_BLOCKED) {
			this.showIncomingCallAlert = this.profile.settings?.allowIncomingCalls === false
				? ALERT_SHOWN
				: ALERT_HIDDEN;
		}

		if (Notification.permission === "denied") this.browserNotificationsDisabled = true;

		if (this.browserNotificationsDisabled) {
			if (Notification.permission === "granted") {
				this.browserNotificationsDisabled = false;
				this.desktopNotificationsEnabled = true;
				this.showNotificationsAlert = ALERT_HIDDEN;
			} else {
				this.desktopNotificationsEnabled = false;
			}
		}

		addEventListener("mouseup", () => this.$store.dispatch("login/activity"));
		addEventListener("keyup", () => this.$store.dispatch("login/activity"));
		addEventListener("storage", handleLogoutEvent);

		// Handler for single session events
		bus.$on("disconnected", () => {
			this.openDialog();
		});

		await this.getStatus();

		if (navigator.mediaDevices?.enumerateDevices()) {
			try {
				// List cameras and microphones.
				const devices = await navigator.mediaDevices.enumerateDevices();
				const mic = devices.some(device => device.kind === AUDIO_DEVICE_NAME);

				this.setIsMicroOn(mic);
			} catch (error) {
				// Silence error
				console.error(`Error occured while enumerating devices: ${error}`);
			}
		}

		window.setInterval(() => {
			this.getStatus();
		}, 120000);
	},

	mounted() {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		const currentPath = window.location.pathname;

		if (isMobile && currentPath.includes(LOGIN_PATH)) {
			const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

			if (vw < MIN_WIDTH) {
				alert(this.$t("small"));
			}
		}
	},

	methods: {
		...mapMutations("conversation", ["setCurrentParticipant"]),
		...mapMutations("voice", ["setIsMicroOn", "enableMicrophone", "disableMicrophone"]),

		handleNotifications(hasNotification, appearTime, clearTime, notificationTitle, title, message, timerName) {
			const setTitle = (title, clearTime) => {
				if (clearTime) {
					setTimeout(() => {
						document.title = title;
					}, clearTime);
				} else {
					document.title = title;
				}
			};

			document.title = hasNotification ? notificationTitle : title;

			if (hasNotification) {
				this.timers[timerName] = setInterval(() => {
					if (hasNotification) {
						document.title = message;
						setTitle(notificationTitle, clearTime);
					}
				}, appearTime + clearTime);
			} else {
				if (this.timers[timerName]) clearInterval(this.timers[timerName]);
				setTitle(title, clearTime);
			}
		},

		openDialog() {
			this.openDisconnectDialog = true;
		},

		async handleSessionDisconnected() {
			this.openDisconnectDialog = false;
			this.$router.go(0);
		},

		async getStatus() {
			try {
				this.status = (await this.axios.get("status")).data;
			} catch (err) {
				console.error("Could not get maintenance status:", err);
			}
		}
	}
};
</script>

<style>
	.pad { margin-left: 56px; }
	.v-banner__actions { align-self: flex-start !important; }
</style>
