var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.devMode
    ? _c(
        "v-menu",
        {
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on: menu, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              fab: "",
                              fixed: "",
                              bottom: "",
                              right: "",
                              "x-small": "",
                              color: _vm.color,
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        menu
                      ),
                      [_c("v-icon", [_vm._v("mdi-flag")])],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            989391289
          ),
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(_vm._s(_vm.$t(`env.${_vm.env}`)))]),
              _c(
                "v-card-text",
                { staticClass: "d-flex flex-column row-gap" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.syncing, color: "primary" },
                      on: { click: _vm.syncConfiguration },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", dark: "" } }, [
                        _vm._v(" mdi-phone-classic "),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("env.redirect-webhooks")) + " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        href: _vm.REPOSITORY_URL,
                        target: "_blank",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "", dark: "" } }, [
                        _vm._v(" mdi-github "),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("env.github")) + " "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }