var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { width: _vm.modalWidth, title: _vm.$t("activate-microphone") },
      on: {
        close: function ($event) {
          return _vm.setIsMicroGuideOpen(false)
        },
        persistent: function ($event) {
          return _vm.setIsMicroGuideOpen(false)
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c("v-card-text", { staticClass: "px-0 text-subtitle-1 pt-0 px-4" }, [
        _vm._v(" " + _vm._s(_vm.$t("microphone-instruction")) + " "),
      ]),
      _c(
        "v-card",
        { staticClass: "px-4", attrs: { flat: "" } },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/img/notifications/micro-prompt.png"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }