var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      staticClass: "ma-3",
      class: { "align-center justify-center column mt-4": !_vm.isOnCall },
    },
    [
      _c("contact-avatar", {
        attrs: {
          size: 60,
          contact: _vm.patientInfo,
          anonymous: _vm.anonymous,
          "go-to-conversation": true,
        },
      }),
      _vm.loadingPatientInfo
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "secondary" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { class: _vm.isOnCall ? "pl-4" : "" },
        [
          !_vm.loadingPatientInfo
            ? _c(
                "span",
                { staticClass: "text-subtitle-1 font-weight-bold truncate" },
                [_vm._v(" " + _vm._s(_vm.participantLabel) + " ")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOnCall && !_vm.durationLoading,
                  expression: "isOnCall && !durationLoading",
                },
              ],
              staticClass: "text-subtitle-1 font-weight-bold",
            },
            [_vm._v(" " + _vm._s(_vm.callDuration) + " ")]
          ),
          _c("v-progress-circular", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.durationLoading,
                expression: "durationLoading",
              },
            ],
            attrs: { size: "15", color: "primary", indeterminate: "" },
          }),
        ],
        1
      ),
      !_vm.isOnCall
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.participantName || _vm.loadingPatientInfo,
                  expression: "participantName || loadingPatientInfo",
                },
              ],
              staticClass: "text-subtitle-1 text--secondary font-weight-bold",
            },
            [_vm._v(" " + _vm._s(_vm.participantPhoneNumber) + " ")]
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "justify-end px-3 my-1" },
        [_vm.isOnCall ? _c("hangUp-button") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }