var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alerts" },
    _vm._l(_vm.list, function (alert, index) {
      return _c(
        "v-alert",
        {
          key: index,
          staticClass: "my-4",
          attrs: {
            border: "top",
            dark: "",
            dismissible: "",
            icon: _vm.getIcon(alert),
            color: alert.type,
            elevation: "4",
            value: alert.show,
          },
        },
        [
          _vm._v(" " + _vm._s(alert.message) + " "),
          _vm.responseMessage(alert.response)
            ? _c("span", [
                _vm._v(" - " + _vm._s(alert.response.data.message) + " "),
              ])
            : _vm._e(),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }