export class UnsupportedAPIExcetion extends Error {
	constructor(message) {
		super(message);
	}
}

export class EnqueueCallException extends Error {
	constructor(message) {
		super(message);
	}
}

export class DequeueCallException extends Error {
	constructor(message) {
		super(message);
	}
}