import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import i18n from "./i18n";
import Package from "../package.json";

// 3rd party
import axios from "axios";
import VueAxios from "vue-axios";
import { Loader } from "google-maps";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueMasonry from "vue-masonry-css";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueNativeNotification from "vue-native-notification";
import VueMeta from "vue-meta";
import { VueMaskDirective } from "v-mask";

// Plugins
import Alert from "./plugins/alert";
import Translation from "./plugins/translation";
import Helper from "./plugins/helper";

// Layouts
import CenteredColumn from "./components/layouts/CenteredColumn.vue";
import Fullscreen from "./components/layouts/Fullscreen.vue";
import OneColumn from "./components/layouts/OneColumn.vue";
import TwoColumns from "./components/layouts/TwoColumns.vue";

// Overlays
import Alerts from "./components/overlays/Alerts.vue";
import Env from "./components/overlays/Env.vue";
import vuetify from "./plugins/vuetify";
import EscapeHtml from "./plugins/escape-html";
import PrettyBytes from "./plugins/pretty-bytes";
import FullName from "./plugins/fullname";
import PhoneNumber from "./plugins/phone";

// Utils
import { phoneNumberFormater as formatPhoneNumber } from "./common/utils";

// Sementic Version
export const version = Package.version;

// Filters
Vue.filter("split", (text, separator, start, end) => {
	return text.split(separator).splice(start, end).join(separator);
});

Vue.filter("truncate", (text, length = 15) => {
	return (text.length > length ? `${text.substring(0, length)}...` : text);
});

// PSH Format stands for (parentheses-space-hyphen formatting)
Vue.filter("phone", function (phoneNumber) {
	return formatPhoneNumber(phoneNumber);
});

Vue.filter("br", (text) => {
	return text.replace("\n", "<br />");
});

// Array Prototypres
Array.prototype.move = function (from, to) {
	this.splice(to, 0, this.splice(from, 1)[0]);
};

// Styles
import "@/assets/css/index.scss";

// Configuration
Vue.config.productionTip = false;

// Load layouts
Vue.component("CenteredColumn", CenteredColumn);
Vue.component("Fullscreen", Fullscreen);
Vue.component("OneColumn", OneColumn);
Vue.component("TwoColumns", TwoColumns);

// Load overlays
Vue.component("Alerts", Alerts);
Vue.component("Env", Env);

// Plugins
Vue.use(EscapeHtml);
Vue.use(PrettyBytes);
Vue.use(FullName);
Vue.use(PhoneNumber);

// External components
Vue.use(VueTelInput);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(VueNativeNotification);

Vue.directive("mask", VueMaskDirective);

// Redirect old urls
switch (window.location.hostname) {
	case "app.bucco.me":
		window.location.href = "https://my.fresk.app";
		break;

	case "rc.bucco.me":
		window.location.href = "https://rc.fresk.app";
		break;
}

// Axios
axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/`;
axios.defaults.withCredentials = true;

axios.interceptors.request.use((config) => {
	config.timeout = 90000;
	config.headers.Authorization = `Bearer ${store.state.login.jwt}`;
	return config;
});

let refreshPromise = null;

axios.interceptors.response.use(
	(response) => {
		// Always make sure refreshPromise is reset
		refreshPromise = null;
		return response;
	},
	async (error) => {
		const { response } = error;
		const originalRequest = error.config;

		// Silence logout error
		if (originalRequest?.url?.includes("/auth/logout")) {
			await store.dispatch("login/showLogin");
			return new Promise(() => {});
		}

		switch (response?.status) {
			case 401:
				if (error.config &&
					store.getters["login/connected"] &&
					!error.config.url.includes("/auth/refresh")) {
					if (!refreshPromise) { // check for an existing in-progress request
						refreshPromise = store.dispatch("login/refreshToken")
							.then(token => {
								refreshPromise = null;
								if (!token?.length) {
									store.dispatch("login/sessionExpired");
								}
								return token;
							}).catch(() => {
								refreshPromise = null;
								store.dispatch("login/sessionExpired");
							});
					}

					return refreshPromise
						?.then(token => {
							if (token?.length) {
								error.config.headers.Authorization = `Bearer ${token}`;
								return axios.request(error.config);
							}
							return new Promise(() => {});
						});
				}
				await store.dispatch("login/sessionExpired");
				return new Promise(() => {});

			case 440:
				if (!originalRequest._rt && store.state.login.jwt?.length) {
					// retry in case the call was called during refresh token
					originalRequest._rt = true;
					originalRequest.headers.Authorization = `Bearer ${store.state.login.jwt}`;
					return axios.request(originalRequest);
				}

				await store.dispatch("login/sessionExpired");
				bus.$emit("disconnected");
				return new Promise(() => {});
		}

		return Promise.reject(error);
	}
);

Vue.use(VueAxios, axios);

// 3rd party
Vue.use(VueMasonry);

// Plugins
Vue.use(Alert);
Vue.use(Translation);
Vue.use(Helper);
Vue.use(VueMeta);

// Create bus instance
export const bus = new Vue();

// VueJs Application
export const Main = new Vue({
	i18n,

	async mounted() {
		window.setInterval(async () => {
			if ("serviceWorker" in navigator) {
				try {
					const reg = await navigator.serviceWorker.getRegistration();

					reg.update();
				} catch (err) {
					console.warn("Could not get service worker registration:", err);
				}
			}
		}, 60000);

		// Setup Google API loader
		const loader = new Loader(process.env.VUE_APP_GMAP_API_KEY, {
			language: this.$i18n.locale,
			libraries: ["places"],
			version: "quarterly"
		});

		await loader.load();
	},

	render: (h) => h(App),
	router,
	store,
	vuetify
}).$mount("#app");
