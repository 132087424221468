var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticClass: "py-2 px-2 mb-1", attrs: { column: "" } },
    [
      _c(
        "v-card-actions",
        [
          _vm.hasIncomingCall
            ? _c(
                "v-row",
                {
                  staticClass: "d-flex justify-space-around",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("dial-button", { attrs: { "is-inbound": true } }),
                  _c("HangUpButton"),
                ],
                1
              )
            : _vm._e(),
          _vm.isOnCall
            ? _c(
                "v-row",
                { staticClass: "d-flex justify-space-between px-3 pb-3" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          outlined: "",
                                          fab: "",
                                          color: "primary",
                                          disabled: _vm.disableForward,
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isTransferCallOpen = true
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-arrow-right-bottom "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2010533932
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("transfer")))])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "button px-3",
                                        class: _vm.blinkButtonClass,
                                        attrs: {
                                          outlined: "",
                                          fab: "",
                                          color: "primary",
                                          disabled: _vm.disableOnHold,
                                          small: "",
                                        },
                                        on: { click: _vm.toggleOnHoldCall },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm.onHoldLoading
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            width: 4,
                                            color: "primary",
                                            indeterminate: "",
                                          },
                                        })
                                      : _c("v-icon", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.activeCall.onHold
                                                  ? "mdi-play"
                                                  : "mdi-pause"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        178988852
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              _vm.activeCall.onHold ? "resume-cta" : "hold-cta"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _vm.isCallQueueEnabled
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "px-3",
                                          attrs: {
                                            outlined: "",
                                            fab: "",
                                            color: "primary",
                                            small: "",
                                            disabled: !_vm.isCallAccepted,
                                          },
                                          on: { click: _vm.sendToQueue },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "md" } },
                                          [
                                            _vm._v(
                                              " mdi-format-list-bulleted-square "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            26423076
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("send-to-queue")))])]
                      )
                    : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "px-3",
                                      attrs: {
                                        outlined: "",
                                        fab: "",
                                        color: "primary",
                                        disabled: _vm.isTransfering,
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("showDialpad")
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { size: "md" } }, [
                                      _vm._v(" mdi-dialpad "),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3500375237
                      ),
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("show-dialpad")))])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isTransferCallOpen
        ? _c("transfer-call", {
            attrs: { open: _vm.isTransferCallOpen, groups: _vm.groups },
            on: {
              cancel: function ($event) {
                _vm.isTransferCallOpen = false
              },
              confirm: function ($event) {
                return _vm.transferCall($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }