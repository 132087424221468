import Vue from "vue";

export default {
	namespaced: true,
	state: () => ({
		queueSid: undefined,
		calls: {},
		timers:{},
		durations: {}
	}),

	getters: {
		queueSid: (state) => state.queueSid,
		queued:(state) =>  Object.values(state.calls).filter(call => call.status === "queued" && (!call.queuedBy && !call.queuedByName)),
		inbound: (state) => Object.values(state.calls).filter(call => call.status === "ringing"),
		active: (state) => Object.values(state.calls).filter(call => call.status === "in-progress"),
		parked: (state) => Object.values(state.calls).filter(call => call.status === "queued" && (call.queuedBy || call.queuedByName)).sort((a, b) => a.parkNo - b.parkNo),
		durations: (state) => state.durations

	},

	mutations: {
		setCall(state, call) {
			const callSid = call.callSid;

			if (state.timers[callSid]) {
				clearInterval(state.timers[callSid]);
				delete state.timers[callSid];
			}

			if (call.status === "completed") {
				if (state.calls[callSid]) {
					Vue.delete(state.calls, callSid);

					if (state.durations[callSid]) {
						delete state.durations[callSid];
					}
				}
				return;
			}

			const start = Math.floor(Date.now()/1000) - ((call.status === "queued" ? call?.waitTotal : call?.duration) || 2);

			Vue.set(state.calls, callSid, call);
			Vue.set(state.durations, callSid, Math.floor(Date.now()/1000) - start);

			state.timers[callSid] = setInterval(() => {
				Vue.set(state.durations, callSid, Math.floor(Date.now()/1000) - start);
			}, 1000);
		},

		releaseCall(state, callSid) {
			console.debug(`[QUEUE] Releasing call ${callSid}`);
			if (state.calls[callSid]) {
				Vue.delete(state.calls, callSid);
				clearInterval(state.timers[callSid]);
				delete state.timers[callSid];
				delete state.durations[callSid];
			}
		},

		releaseCalls(state) {
			console.debug("[QUEUE] Releasing calls");
			for (const callSid of Object.keys(state.calls)) {
				Vue.delete(state.calls, callSid);
			}

			for (const callSid of Object.keys(state.timers)) {
				clearInterval(state.timers[callSid]);
				delete state.timers[callSid];
			}

			for (const callSid of Object.keys(state.durations)) {
				delete state.durations[callSid];
			}
		}
	},

	actions: {
		setQueue({ commit }, calls) {
			try {
				if (calls.queued?.length) {
					for (const queue of calls.queued) {
						if (queue?.callSid) {
							commit("setCall", { ...queue, status:"queued" });
						}
					}
				}

				if (calls.actives?.length) {
					for (const active of calls.actives) {
						if (active?.callSid) {
							commit("setCall", active);
						}
					}
				}
			} catch (error) {
				console.error("Error fetching queue", error);
			}
		},

		updateQueue({ commit }, call) {
			console.debug("voiceAttributes.status : ", call.status, " call ", call);
			if (call?.callSid) {
				commit("setCall", call);
			}
		}
	}
};
