<template>
	<v-container>
		<v-row>
			<v-col>
				<slot />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {};
</script>
