<template>
	<v-layout column class="mx-5">
		<div class="mb-3">
			{{ $t("support-instruction") }}
		</div>

		<v-form ref="form" v-model="valid">
			<v-layout column>
				<v-textarea
					v-model="inputForm.description"
					outlined
					:hint="$t('problem-info')"
					:label="$t('problem-description')"
					:rules="[rules.required(form.description, 'description')]"
					background-color="light-primary"
				/>

				<v-layout align-start>
					<v-file-input
						v-model="imgFileInput"
						outlined
						prepend-icon=""
						append-icon="mdi-paperclip"
						dense
						:label="$t('screenshot')"
						background-color="light-primary"
						accept="image/png, image/jpeg, image/svg+xml"
						clearable
					/>

					<v-tooltip bottom>
						<template #activator="{ on, attrs }">
							<v-icon color="primary" v-bind="attrs" class="mt-2 ml-2" v-on="on">
								mdi-information-outline
							</v-icon>
						</template>
						<span>{{ $t("screenshot-tooltip") }}</span>
					</v-tooltip>
				</v-layout>
			</v-layout>
		</v-form>
	</v-layout>
</template>

<script>
import { RequiredValidator, EmailValidator } from "@/validators";
import { mapGetters } from "vuex";

export default {
	name: "SupportForm",

	props:{
		value : {
			type : Boolean,
			default : false
		},

		fullPath :  {
			type : String,
			default : undefined
		},

		form : {
			type : Object,
			default : undefined
		},

		supportDialog : {
			type : Boolean,
			default : false
		}
	},

	data() {
		return {
			valid: false,
			imgFileInput: undefined,
			rules: {
				email: EmailValidator,
				required: RequiredValidator
			},
			inputForm: {}
		};
	},

	computed: {
		...mapGetters("login", ["profile"]),
		...mapGetters("clinic", ["isFrance"])
	},

	watch: {
		form(updatedForm) {
			this.inputForm = updatedForm;
		},

		valid(val) {
			this.$emit("updateValidation", val);
		},

		imgFileInput(newImgFileInput) {
			if (newImgFileInput) {
				const reader = new FileReader();

				reader.readAsDataURL(this.imgFileInput);
				reader.onload = () => {
					this.inputForm.screenshot = reader.result;
				};
			}
		},

		supportDialog(isCloseDialog) {
			if (isCloseDialog) {
				this.imgFileInput = undefined;
				this.$refs.form.reset();
				this.$emit("onDialogClose");
			}
		}
	},

	mounted() {
		this.inputForm = this.form;
		this.$refs.form.reset();
	}
};
</script>
