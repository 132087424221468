var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status?.enabled && _vm.status?.overlay
    ? _c(
        "v-overlay",
        { attrs: { absolute: "" } },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height",
              attrs: { "align-content": "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "text-subtitle-1 text-center font-weight-bold",
                  attrs: { cols: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("loadingText")) + " ")]
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-progress-linear", {
                    attrs: { indeterminate: "", rounded: "", height: "12" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }