var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        width: "700",
        title: _vm.$t("logout"),
        type: "question",
        "is-cta-disable": _vm.isCtaDisable,
        "cancel-text": _vm.$t("cancel"),
        "confirm-text": _vm.$t("logout"),
      },
      on: {
        cancel: function ($event) {
          _vm.showDialog = false
        },
        confirm: _vm.logoutUser,
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "py-0 px-4 mb-4" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "text-body-1",
                attrs: { transition: "fade-transition" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("logout-text1")) + " ")]
            ),
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "text-body-1",
                attrs: { transition: "fade-transition" },
              },
              [_vm._v(" " + _vm._s(_vm.$t("logout-text2")) + " ")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }