import * as escape from "escape-html";

export default {
	install(Vue) {
		Vue.directive("escape-html", {
			inserted: function (el) {
				el.innerHTML = escape(el.innerHTML);
			}
		});
	}
};
