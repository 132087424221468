import { getField, updateField } from "vuex-map-fields";
import { DEFAULT_WEEK_SCHEDULE, DEFAULT_WORKDAY_SCHEDULE } from "../common/const";
import { cloneDeep } from "lodash";

const getDefaultState = () => ({
	needsIvr: false,
	paymentMethodToken: undefined,
	user: {
		locale: undefined,
		email: undefined,
		groups: undefined,
		name: {
			first: undefined,
			last: undefined
		},
		password: undefined,
		role: "user"
	},
	users: [],
	groups: [
		{
			email: undefined,
			label: "Principal",
			main: true,
			schedule: {
				week: cloneDeep(DEFAULT_WORKDAY_SCHEDULE)
			}
		}
	],
	clinic: {
		address: undefined,
		email: undefined,
		location: {
			latitude: 45.2744744,
			longitude: -71.97408829999999
		},
		logo: undefined,
		name: {
			"fr-FR": undefined
		},
		phone: undefined,
		schedule: {
			week: cloneDeep(DEFAULT_WORKDAY_SCHEDULE)
		},
		sector: "",
		services: [""],
		url: undefined
	}
});

export default {
	getters: {
		getField,
		groups: state => state.groups,
		dirtyForm: state => state.dirtyForm,
		dirtyTimeRange: state => state.dirtyTimeRange,
		dirtyData: state => state.dirtyData,
		needsIvr: state => state.needsIvr,
		isInvalidTime: state => state.isInvalidTime,
		users: state => state.users,
		validForm: state => state.validForm
	},
	mutations: {
		updateField,
		addGroup(state) {
			state.groups.push({
				email: undefined,
				label: undefined,
				main: false,
				schedule: {
					week: cloneDeep(DEFAULT_WORKDAY_SCHEDULE)
				}
			});
		},
		addUser(state) {
			state.users.push(getDefaultState().user);
		},
		setNeedsIvr(state, val) {
			state.needsIvr = val;
		},
		removeGroup(state, index) {
			// remove group from users if already assigned
			state.users.forEach((user, i) => {
				if (user.groups) {
					user.groups = user.groups.filter(group => group !== state.groups[index].label);
				}
			});
			state.groups.splice(index, 1);
		},
		removeUser(state, index) {
			state.users.splice(index, 1);
		},
		storeFirstUser(state, newUser) {
			state.users = [newUser];
			state.clinic = getDefaultState().clinic;
		},
		resetState(state) {
			Object.assign(state, getDefaultState());
		},
		timeRangeState(state, payload) {
			state.isInvalidTime = payload;
		},
		setGroupLabel(state, { oldValue, newValue, index }) {
			state.groups[index].label = newValue;
			if (oldValue !== undefined) {
				state.users.forEach((user) => {
					if (user.groups?.includes(oldValue)) {
						var oldLabelIndex;

						user.groups?.some((group, index) => {
							oldLabelIndex = index;
							return group == oldValue;
						});

						user.groups?.splice(oldLabelIndex, 1);
						user.groups?.push(newValue);
					}
					return user;
				});
			}
		},
		setGroupEmail(state, email) {
			// Only set main group
			state.groups[0].email = email;
		},
		setFormValidity(state, payload) {
			state.validForm = payload;
		},
		setDirtyForm(state, payload) {
			state.dirtyForm = payload;
		},
		setDirtyTimeRange(state, payload) {
			state.dirtyTimeRange = payload;
		},
		setDirtyData(state, payload) {
			state.dirtyData = payload;
		}
	},
	namespaced: true,
	state: () => ({
		needsIvr: false,
		isInvalidTime: false,
		validForm: false,
		dirtyForm: false,
		dirtyTimeRange: false,
		dirtyData: false,
		paymentMethodToken: undefined,
		users: [],
		groups: [
			{
				email: undefined,
				label: "Principal",
				main: true,
				schedule: {
					week: cloneDeep(DEFAULT_WORKDAY_SCHEDULE)
				}
			}
		],
		clinic: {
			address: undefined,
			email: undefined,
			location: {
				latitude: 45.2744744,
				longitude: -71.97408829999999
			},
			logo: undefined,
			name: {
				"fr-FR": undefined
			},
			phone: undefined,
			schedule: {
				week: cloneDeep(DEFAULT_WORKDAY_SCHEDULE)
			},
			sector: "",
			services: [],
			url: undefined
		}
	})
};
