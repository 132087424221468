var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm._t("default"),
      _c(
        "v-row",
        [_c("v-col", [_vm._t("left")], 2), _c("v-col", [_vm._t("right")], 2)],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }