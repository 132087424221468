<template>
	<img
		v-if="icon"
		:src="icon"
		:alt="sentiment"
		:width="size"
		:height="size"
>
</template>

<script>
export const Sentiment = {
	Negative: "Negative",
	Neutral: "Neutral",
	Positive: "Positive"
};

export default {
	name: "SentimentEmoji",

	props: {
		sentiment: {
			type: String,
			default: Sentiment.Neutral
		},

		size: {
			type: Number,
			default: 32
		},

		outline: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		icon () {
			switch (this.sentiment) {
				case Sentiment.Negative: return require("@/assets/img/emoji/unhappy-256px.svg");
				case Sentiment.Positive: return require("@/assets/img/emoji/happy-256px.svg");
				default: return undefined;
			}
		},

		color () {
			switch (this.sentiment) {
				case Sentiment.Negative: return "error";
				case Sentiment.Positive: return "avatar";
				default: return undefined;
			}
		}
	}
};
</script>
