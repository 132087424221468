var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        width: "650px",
        title: _vm.$t("ios-enable-notification"),
        type: "question",
        "cancel-text": _vm.$t("cancel"),
        "confirm-text": _vm.$t("activate"),
      },
      on: {
        cancel: function ($event) {
          _vm.isOpen = false
        },
        confirm: _vm.triggerIOSNotification,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c("v-card-text", { staticClass: "px-4 pt-0 text-subtitle-1" }, [
        _vm._v(" " + _vm._s(_vm.$t("ios-audio-notification")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }