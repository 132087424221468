import { updateField, getField } from "vuex-map-fields";
import axios from "axios";
import { CONSULTATION, PROTOCOL, KIT, ARTICLE } from "@/common/const";
import { bus } from "@/main.js";
import { partition } from "lodash";
import { currentLocale } from "@/common/utils";

const getDefaultState = () => ({
	isInChat: false,
	activePostType: undefined,
	hasOwnTextBlocks: false,
	allPosts: [],
	allTextBlocs: [],
	currentExpertise : undefined,
	openedFile: undefined,
	dirtyForm: false,
	isAgenda: false,
	isQuickResponses: false,
	allExpertises: []
});

export default {
	getters: {
		filteredPosts: (state, rootState) => {
			const clinicNameFilter = state.allPosts.filter(file => {
				if (file?.clinic?.name) {
					const names = file.clinic.name;

					return Object.values(names).some(name => name === state.postFilter);
				} else {
					return false;
				}
			});

			const aliasFilter = state.allPosts.filter(file => file.alias === state.postFilter);

			if (!state.postFilter) {
				return [];
			}
			if (clinicNameFilter.length) {
				return clinicNameFilter;
			}
			if (aliasFilter.length) {
				return aliasFilter;
			}
		},

		getLoaderShow: state => {
			return state.showLoader;
		},

		getPostsUpdated: state => {
			return state.postsUpdated;
		},

		dirtyForm: state => state.dirtyForm,

		getField
	},
	mutations: {
		updateField,

		setHasOwnTextBlocks: (state, doesHasOwnTextBlocks) => state.hasOwnTextBlocks = doesHasOwnTextBlocks,

		setAllPosts: (state, newPosts) => state.allPosts = newPosts,

		setAllTextBlocs: (state, newTextBlocs) => state.allTextBlocs = newTextBlocs,

		setDirtyForm: (state, payload) => state.dirtyForm = payload,

		resetPostFilter: (state) => state.postFilter = undefined,

		setAllExpertises: (state, allExpertises) => state.allExpertises = allExpertises,

		setLastGetAllPostRequest: (state, lastRequest) => state.lastGetAllPostRequest = lastRequest,

		setShowLoader(state, value) {
			state.showLoader = value;
		},

		setPostsUpdated(state, value) {
			state.postsUpdated = value;
		}
	},
	actions: {
		async getHasOwnTextBlocks({ rootGetters, rootState, commit, state }, args) {
			let url = `/organizations/${args.clinicId}/text-blocks/has-own`;
			const doesHasOwnTextBlocks = (await axios.get(url)).data;

			commit("setHasOwnTextBlocks", doesHasOwnTextBlocks);
		},

		async getAllTextBloc({ rootGetters, rootState, commit, state }, args) {
			const textFiles = [];

			try {
				let urlTextBlocks = `/organizations/${args.clinicId}/text-blocks`;

				let urlCommunicationTemplates = `communication-templates/?type=sms&userId=${rootState.login.profile.id}`;

				if (args.locale) {
					urlTextBlocks = urlTextBlocks + `?locale=${args.locale}`;
					urlCommunicationTemplates = urlCommunicationTemplates + `&locale=${args.locale}`;
				}
				const requests = [axios.get(urlTextBlocks)];

				if (rootGetters["login/isMarketing"]) {
					requests.push(axios.get(urlCommunicationTemplates));
				}

				const [allTextBloc, allTemplates] = await Promise.all(requests);

				allTemplates?.data?.forEach(file => {
					textFiles.push({
						id: file.id,
						alias: file.title,
						body: file.content,
						template: true,
						pinned: file.pinned
					});
				});

				allTextBloc?.data?.forEach(file => {
					textFiles.push({
						id: file.id,
						alias: file.title,
						body: file.body,
						template: false,
						pinned: file.pinned
					});
				});
				const [pinnedTextFiles, unpinnedTextFiles] = partition(textFiles, textFile => { return textFile.pinned; });

				pinnedTextFiles.sort((a, b) => a.alias.localeCompare(b.alias)).sort((a, b) => b.template - a.template);
				unpinnedTextFiles.sort((a, b) => a.alias.localeCompare(b.alias)).sort((a, b) => b.template - a.template);
				const filteredTextFiles = [ ...pinnedTextFiles, ...unpinnedTextFiles];

				commit("setAllTextBlocs", filteredTextFiles);
			} catch (err) {
				throw err;
			} finally {
				bus.$emit("resetDisabledNavigation");
				state.loadingPosts = false;
			}
		},

		async getSortedPosts({ commit, rootGetters, rootState, state }, { service, localeFilter = rootState.login.profile.locale } ) {

			if (!state.activePostType) {
				commit("setAllPosts", []);
				bus.$emit("resetDisabledNavigation");
				return;
			}

			commit("setPostsUpdated", false);
			state.loadingPosts = true;
			state.showLoader = true;

			let typeName, allPosts;

			try {
				const typeLookup = new Map();

				typeLookup.set(CONSULTATION, "c");
				typeLookup.set(PROTOCOL, "p");
				typeLookup.set(KIT, "k");
				typeLookup.set(ARTICLE, "a");

				typeName = typeLookup.get(Number(state.activePostType));

				let postRequestUrl = `posts/search?type=${typeName}&services=${service}`;

				if (localeFilter) {
					postRequestUrl = postRequestUrl + `&locale=${localeFilter}`;
				}

				if (rootGetters["login/isMarketing"]) {
					postRequestUrl = postRequestUrl + "&showAll";
				} else {
					postRequestUrl = postRequestUrl + `&draft=${false}&clinic=${rootState.login.profile.clinic}`;
				}

				const getAllPostPromise = axios.get(postRequestUrl);

				commit("setLastGetAllPostRequest", getAllPostPromise);

				const reponse = await getAllPostPromise;

				if (getAllPostPromise === state.lastGetAllPostRequest) {
					allPosts = reponse.data;

					const [pinnedDocuments, unpinnedDocuments] = partition(allPosts, document => { return document.pinned; });

					pinnedDocuments.sort((a, b) => a.alias.localeCompare(b.alias));
					unpinnedDocuments.sort((a, b) => a.alias.localeCompare(b.alias));
					allPosts = [...pinnedDocuments, ...unpinnedDocuments];

					commit("setAllPosts", allPosts);
					commit("setPostsUpdated", true);
					commit("setLastGetAllPostRequest", null);
				}
			} catch (err) {
				throw err;
			} finally {
				bus.$emit("resetDisabledNavigation");
				state.loadingPosts = false;
				state.showLoader = false;
			}
		},

		async getExpertises({ commit, rootGetters, rootState }) {
			try {
				let allExpertises = (await axios.get(`services?sector=${rootGetters["clinic/clinicData"].sector}`)).data;

				allExpertises = allExpertises.filter(expertise => rootGetters["clinic/clinicData"].services.includes(expertise.id));
				const sortedExpertises = allExpertises.sort((a, b) => a.name[currentLocale(rootState.login.profile.locale)].localeCompare(b.name[currentLocale(rootState.login.profile.locale)]));

				let generalIndex = "";

				if (rootGetters["clinic/clinicData"].sector === "notary") {
					generalIndex = sortedExpertises.findIndex(item => item.id === "general-notary");
				} else {
					generalIndex = sortedExpertises.findIndex(item => item.id === "general");
				}

				sortedExpertises.move(generalIndex, 0);
				commit("setAllExpertises", sortedExpertises);
			} catch (err) {
				console.error(err);
				throw err;
			}
		}
	},
	namespaced: true,

	state: () => ({
		isInChat: false,
		activePostType: undefined,
		allPosts: [],
		lastGetAllPostRequest: undefined,
		allTextBlocs: [],
		postFilter: undefined,
		postsUpdated: false,
		currentExpertise : undefined,
		openedFile: undefined,
		dirtyForm: false,
		isAgenda: false,
		loadingPosts: false,
		selectedKit: undefined,
		selectedFastMessage: undefined,
		textResetPagination: false,
		kitsResetPagination: false,
		isQuickResponses: false,
		allExpertises: [],
		selectedResponseId: undefined,
		selectedDocumentsIds: [],
		showLoader: false
	})
};
