var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            fab: "",
                            color: "error",
                            elevation: "0",
                            disabled: _vm.disableHangUp,
                            small: !_vm.isOnCall,
                          },
                          on: {
                            click: function ($event) {
                              _vm.isOnCall
                                ? _vm.handleHangup()
                                : _vm.handleReject()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-phone-hangup")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t(_vm.isOnCall ? "hangup-cta" : "ignore"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }