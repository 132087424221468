var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "max-width": "350",
        "retain-focus": false,
      },
      model: {
        value: _vm.showDraggableCallDialog,
        callback: function ($$v) {
          _vm.showDraggableCallDialog = $$v
        },
        expression: "showDraggableCallDialog",
      },
    },
    [_vm.showDraggableCallDialog ? _c("call-details") : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }