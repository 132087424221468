import axios from "axios";
import { EMAIL_PROVIDERS, AUTHENTICATION_WINDOW_NAME } from "@/common/const";
import i18n from "../../i18n";
import store from "../../store";

const openWindowPopup = async (authUrl) => {
	const width = 800;
	const height = 600;
	const top = window.top.outerHeight / 2 + window.top.screenY - (width / 2);
	const left = window.top.outerWidth / 2 + window.top.screenX - (height / 2);

	const selfWindow = window.open(authUrl, AUTHENTICATION_WINDOW_NAME, `left=${left},top=${top},width=${width},height=${height}`);

	return await new Promise((resolve) => {
		const interval = setInterval(async () => {
			if (selfWindow?.closed) {
				clearInterval(interval);
				resolve();
			}
		}, 1000);
	});
};

export const getAuthType = async (inboxEmail) => {
	const { data } = await axios.post("/oauth2/type", {
		inboxEmail
	});

	return data;
};

export const getEmailProviderAccess = async (type, inboxEmail) => {
	const { data } = await axios.post(`/oauth2/${EMAIL_PROVIDERS[type]}`, {
		inboxEmail
	});

	return data;
};

export const sendEMail = async (patientId, subject, body, attachments = [], inboxEmail, type) => {
	const formEmail = new FormData();

	formEmail.append("inboxEmail", inboxEmail);
	formEmail.append("subject", subject);
	formEmail.append("body", body);

	attachments.forEach(attachment => {
		formEmail.append("attachments", attachment);
	});

	return await axios({
		method: "post",
		url: `/email-sync/${EMAIL_PROVIDERS[type]}/send/${patientId}`,
		data: formEmail,
		headers: { "Content-Type": "multipart/form-data" }
	});
};

export const disconnectEmailProvider = async (inboxEmail) => {
	const { type } = await getAuthType(inboxEmail);

	return await axios.post(`/oauth2/${EMAIL_PROVIDERS[type]}/logout`, {
		inboxEmail
	});
};

export const connectEmailProvider = async (calledSource, inboxEmail) => {
	const authUrl = await getEmailProviderAccess(calledSource, inboxEmail);

	return openWindowPopup(authUrl);
};

export const downloadEmailAttachment = async (url, name, contentType, inboxEmail) => {
	try {
		const response = await axios.post(url, { inboxEmail }, { responseType: "blob" });
		const blob = new Blob([response.data], { type: contentType });
		const link = document.createElement("a");

		link.href = URL.createObjectURL(blob);
		link.download = name;
		link.click();
		URL.revokeObjectURL(link.href);
	} catch {
		store.commit("alerts/add", {
			type: "error",
			message: i18n.t("error.download-attachment"),
			timeout: true
		}, { root: true });
	}
};

export const getAttachmentAsBase64 = async (inboxEmail, file) => {
	const response = await axios.post(file, {
		inboxEmail
	}, {
		responseType: "arraybuffer"
	});

	const fileInBase64 = arrayBufferToBase64(response.data);

	return fileInBase64;
};

export const arrayBufferToBase64 = async (buffer) => {
	const bytes = new Uint8Array( buffer );

	const binary = bytes.reduce((binary, byte) => {
		binary += String.fromCharCode(byte);
		return binary;
	}, "");

	return window.btoa( binary );
};
